<template>
    <validation-provider
        ref="validator"
        #default="{ errors, required }"
        :name="internalLabel"
        :vid="internalVid"
        :rules="rules"
        slim
    >
        <b-form-group
            :name="internalVid"
            :id="fieldsetId"
            :description="internalDescription"
            :label="internalLabel"
            :label-for="inputId"
            :state="errors.length == 0"
        >
            <template #label>
                <slot name="label" :inputId="inputId" :label="internalLabel">
                    <label :id="fieldsetId + '__BV_label_'" :for="inputId" class="d-block" v-if="internalLabel" :class="labelClass">
                        {{ internalLabel }}
                        <span v-if="required" class="text-danger">*</span>
                    </label>
                </slot>
            </template>

            <template v-if="mask">
                <b-form-input
                    ref="input"
                    :id="inputId"
                    class="w-full"
                    :class="{'is-invalid' : errors.length > 0}"
                    :label="internalLabel"
                    v-model="internalValue"
                    @change="dispatchEvent('change', internalValue)"
                    :placeholder="internalPlaceholder"
                    :data-cy="internalTestId"
                    v-bind="$attrs"
                    v-mask="Array.isArray(mask) ? [...mask] : mask"
                    autocomplete="off"
                />
            </template>
            <template v-else>
                <b-form-input
                    ref="input"
                    :id="inputId"
                    class="w-full"
                    :class="{'is-invalid' : errors.length > 0}"
                    :label="internalLabel"
                    v-model="internalValue"
                    @change="dispatchEvent('change', internalValue)"
                    :placeholder="internalPlaceholder"
                    :data-cy="internalTestId"
                    v-bind="$attrs"
                    autocomplete="off"
                />
            </template>
            <small class="text-danger">{{ errors[0] }}</small>

        </b-form-group>


    </validation-provider>
</template>

<script>
import FormFieldBase from '../mixins/Base'
import { ValidationProvider } from 'vee-validate'

/**
 * Componente que encapsula o b-form-input.
 * @see https://bootstrap-vue.org/docs/components/form-input
 */
export default {
    name: 'form-field-input',
    mixins : [
        FormFieldBase
    ],
    components: {
        ValidationProvider
    },
    props : {
        /**
         * Usado caso seja necessário aplicação de máscara no campo de texto
         *
         * @see https://vuejs-tips.github.io/vue-the-mask/
         */
        mask : {
            type: String|Array,
            required: false,
            default: ''
        }
    }
}
</script>
<!--
<docs>
```vue
  <template>
    <form-field-input v-model="inputValue" name="field-name">Default Example Usage</form-field-input>
  </template>

  <script>
    export default {
      data() {
        return { inputValue: '' };
      }
    }
  </script>
```
</docs>
-->
