<template>
  <div class="container-xl vacancy-list p-0 pt-5 pt-md-10">
    <vacancy-search-bar @search="applyFilter" class="mb-4" />

    <div
      class="border px-4 py-3 rounded-top bg-white d-flex gap-2"
      :class="{ 'overflow-x-scroll': isMobile() }"
    >
      <div v-for="(component, name) in filters" :key="name">
        <component
          ref="vacancy_filter"
          v-if="component.is && component.show"
          :key="'vacancy.filters.' + name"
          :is="component.is"
          :name="'vacancy.filters.' + name"
          v-model="vacancies.conditions[name]"
          v-bind="component"
          untracked
          class="m-0"
          :class="component.componentClasses"
          @search="applyFilter"
        />
      </div>
      <div class="flex-grow text-right">
        <b-button variant="link" size="xs" @click="clearConditions()" class="">
          {{ $t("vacancy.cleanFilters") }}
        </b-button>
      </div>
    </div>

    <div
      v-if="notFound && !isVacancyLoading"
      class="border rounded-bottom bg-white d-flex gap-4 flex-column align-items-center p-8"
    >
      <div class="rounded-pill p-5 bg-surface-informational mb-2">
        <SearchIcon />
      </div>
      <h4 class="text-lg font-weight-semibold">
        {{ $t("vacancy.empty_vacancy") }}
      </h4>
      <p class="m-0">{{ $t("vacancy.keep_searching") }}</p>
    </div>

    <div class="row no-gutters conteudo" v-if="!isVacancyLoading && !notFound">
      <div
        class="d-flex flex-column col-lg-5 cards"
        v-if="!forceFullVacancy || !isMobile()"
        @scroll="loadMoreItems"
      >
        <b-card
          bg-variant="primary"
          text-variant="white"
          class="vacancy-card border-right-0 border-top-0 rounded-0"
          v-if="!isVacancyLoading"
        >
          <p class="title text-lg m-0" v-if="resultText">{{ resultText }}</p>
          <div class="attrs">
            {{ $tc("vacancy.filters.results", vacancies.qtd_results) }}
          </div>
          <div class="attrs">
            {{ $tc("vacancy.filters.count_paginated_results", vacanciesSize) }}
            {{
              $tc("vacancy.filters.count_all_results", vacancies.qtd_results)
            }}
          </div>
        </b-card>
        <vacancy-card
          v-for="item in vacancies.paginated_list"
          :key="item.id"
          @click="show(item.id)"
          :class="{ selected: item.id == selectedID }"
          :item="item"
          type="stacked"
          :rounded="false"
          style="min-height: 104px"
        />
        <div
          class="vacancy-card card cursor-pointer border-right-0 border-top-0 rounded-0"
          v-if="isLoading"
        >
          <div class="card-body text-center">
            <b-spinner variant="primary" />
          </div>
        </div>
      </div>
      <vacancy-full
        class="col-lg-7"
        :class="{ 'is-focused': selectedID && showFullVacancy }"
        :item="vacancy"
        v-if="selectedID"
        :is-loading="fullVacancyLoading"
        :options="{ header_image: true, tags: false }"
        :rounded="false"
        @close="forceFullVacancy = false"
      />
      <!-- </b-overlay> -->
      <!-- v-if="selectedID && showFullVacancy" -->
    </div>

    <div
      v-if="isVacancyLoading"
      class="border rounded-bottom bg-white d-flex gap-4 flex-column align-items-center p-8"
    >
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import { useTheme } from "@state/system/theme";
import { useDomain } from "@state/domain/domain";
import { SearchIcon } from "@vue-hero-icons/solid";
import * as FormComponents from "@components/forms";
import { useCompanyForm } from "@state/company/form";
import { useVacancies } from "@state/vacancy/vacancies";
import VacancyCard from "@blocks/vacancy/VacancyCard.vue";
import VacancyFull from "@blocks/vacancy/VacancyFull.vue";
import VacancySearchBar from "@blocks/vacancy/VacancySearchBar.vue";

export default {
  mixins: [companyMixin],
  components: {
    VacancyCard,
    VacancyFull,
    VacancySearchBar,
    ...FormComponents,
    SearchIcon,
  },
  setup() {
    const vacancies = useVacancies();
    const domain = useDomain();
    const forms = useCompanyForm();
    const theme = useTheme();

    forms.load("vacancies", true);

    return {
      forms,
      domain,
      vacancies,
      isMobile: theme.isMobile,
    };
  },
  created() {
    this.forms
      .fields(
        "vacancies",
        false,
        "filters.interval",
        "filters.contract_type",
        "filters.workplace_type",
        "filters.professional_area"
      )
      .then((fields) => {
        this.isVacancyLoading = true;

        for (let i in fields.filters) {
          this.$set(this.filters, i, fields.filters[i]);
        }

        // Apply filters based on url params
        if (!_.isEmpty(this.$route.query.text)) {
          this.vacancies.conditions.text = this.$route.query.text || ''
        }

        if (!_.isEmpty(this.$route.query.location)) {
          this.vacancies.conditions.location = this.$route.query.location || ''
        }

        this.vacancies
          .searchPaginated({})
          .then((response) => {
            if (response.length > 0) {
              this.show(response[0].id, false);
              this.notFound = false;
            } else {
              this.notFound = true;
            }
          })
          .finally((response) => {
            this.isVacancyLoading = false;
          });

        if (this.$auth.check()) {
          this.vacancies.favoriteListLoad();
        }
      });
  },
  destroyed() {
    this.vacancies.resetConditions();
  },
  data() {
    return {
      page: 1,
      isLoading: false,
      vacancy: null,
      actualList: [],
      filters: {},
      state: {
        selected: false,
      },
      isVacancyLoading: false,
      fullVacancyLoading: false,
      selectedID: null,
      forceFullVacancy: false,
      resultText: "",
      notFound: false,
      loadMoreVacancies: true,
    };
  },
  computed: {
    vacanciesSize() {
      return _.size(this.vacancies.paginated_list);
    },
    showFullVacancy() {
      return !this.isMobile() || this.forceFullVacancy;
    },
    searchBarSize() {
      return _.size(this.filters) + 1;
    },
  },
  methods: {
    canRequestNow() {
      return !this.isLoading && this.vacancies.loadMoreItems;
    },
    loadMoreItems(event) {
      if (this.canRequestNow()) {
        let heightToLoad =
          event.currentTarget.scrollHeight -
          event.currentTarget.clientHeight -
          event.currentTarget.scrollTop;

        if (heightToLoad <= 100) {
          this.isLoading = true;
          this.vacancies.searchPaginated().then((response) => {
            this.isLoading = false;
          });
        }
      }
    },
    backToHome() {
      router.push({ name: "home" });
    },
    show(id, forceShow = true) {
      this.selectedID = id;

      this.fullVacancyLoading = true;
      this.vacancies.read(id, false).then((res) => {
        this.vacancy = res.data;
        this.forceFullVacancy = forceShow;

        this.fullVacancyLoading = false;
      });

      this.$forceUpdate();
    },
    clearConditions() {
      this.vacancies.resetConditions();
      this.resultText = null;
      this.applyFilter();
      this.$forceUpdate();
      this.$router.push({query: {}});
    },
    applyFilter() {
      if (this.vacancies.conditions.text.length > 0) {
        this.resultText = this.vacancies.conditions.text + " em: ";
        if (this.vacancies.conditions.location.length > 0) {
          this.resultText += this.vacancies.conditions.location;
        } else {
          this.resultText += "Brasil";
        }
      } else if (this.vacancies.conditions.location.length > 0) {
        this.resultText = "Vagas em: " + this.vacancies.conditions.location;
      }

      this.$router.push({query: {
        text: this.vacancies.conditions.text || '',
        location: this.vacancies.conditions.location || ''
      }})

      this.isVacancyLoading = true;
      this.vacancies
        .searchPaginated({}, true)
        .then((response) => {
          if (response.length > 0) {
            this.show(response[0].id, false);
            this.notFound = false;
          } else {
            this.notFound = true;
          }
        })
        .finally(() => {
          this.isVacancyLoading = false;
        });
    },
    handleScroll(e) {
      var scrollPos = e.target.scrollTop;
      this.showImg = scrollPos <= 0;
    },
  },
  // watch: {
  //   "vacancies.conditions": {
  //     handler(newValue, oldValue) {
  //       this.isVacancyLoading = true;
  //       this.vacancies
  //         .searchPaginated({}, true)
  //         .then((response) => {
  //           if (response.length > 0) {
  //             this.show(response[0].id, false);
  //             this.notFound = false;
  //           } else {
  //             this.notFound = true;
  //           }
  //         })
  //         .finally(() => {
  //           this.isVacancyLoading = false;
  //         });
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
