<template>
  <div class="" v-if="!$root.loading">
    <div class="d-flex flex-column gap-2 align-items-start">
      <h2 class="text-3xl font-weight-bold">
        {{ application.vacancy.occupation }}
      </h2>

      <h6 class="text-sm">{{ vacancySubtitle }}</h6>
      <h6>
        <strong>{{ $t("application.status_application") }}</strong>
        <b-badge
          :variant="
            getStatusVariant(
              application.application.candidate_status,
              application.application.status
            )
          "
          class="mt-3"
        >
          {{
            $t(
              getStatus(
                application.application.candidate_status,
                application.application.status,
                application.application.grade
              )
            )
          }}
        </b-badge>
      </h6>
    </div>

    <div v-if="application">
      <p class="message-title">
        {{ $t("application.applicationStep") }}.<br />
        {{ $t("application.completeSteps") }}.
      </p>
      <div class="accordion">
        <div class="accordion-item">
          <button
            class="accordion-button text-left"
            aria-expanded="true"
            aria-controls="collapseOne"
            v-b-toggle.vacancy-details
          >
            <p class="my-0">Detalhes da Vaga</p>
          </button>
          <b-collapse id="vacancy-details" role="tabpanel">
            <div class="accordion-body">
              <vacancy-details :item="application.vacancy" />
            </div>
          </b-collapse>
        </div>
      </div>

      <div class="divider">
        <hr class="w-100" />
      </div>

      <div class="accordion" id="accordionExample">
        <div
          class="accordion-item"
          v-for="(step, index) in application.steps"
          :key="'collapse-' + index"
        >
          <h2 class="accordion-header application-steps">
            <button
              class="accordion-button"
              type="button"
              aria-expanded="true"
              aria-controls="collapseOne"
              v-b-toggle="collapaseBaseId + '-' + index"
            >
              <!-- @todo Move rpara componente -->
              <div v-if="step.status == 3" class="step-icon invalid-step">
                <XIcon />
              </div>
              <div v-if="step.status == 2" class="step-icon completed-step">
                <CheckIcon />
              </div>
              <div v-if="step.status == 1" class="step-icon current-step">
                <LockOpen />
              </div>
              <div v-if="step.status == 0" class="step-icon blocked-step">
                <LockClosed />
              </div>
              <p class="my-0">
                {{ step.title ? $t(step.title) : $t(step.stage.description) }}
              </p>
            </button>
          </h2>
          <b-collapse
            :id="collapaseBaseId + '-' + index"
            role="tabpanel"
            v-bind="collapseBindings"
          >
            <div class="accordion-body">
              <div v-if="step.is">
                <component :is="step.is" :step="step" />
              </div>
              <div v-else>
                {{ step.content }}
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <!--
            <div class="next-step-div">
                <div class="step-icon blocked-step"><font-awesome-icon icon="star" /></div>
                <p>{{ $t('application.nextStep') }}</p>
            </div>
            <p class="next-step-message">{{ $t('application.completeMessage') }}.</p>
            -->
    </div>
  </div>
</template>

<script>
import { useApplications } from "@state/user/applications";
import companyMixin from "@state/company";

import vacanciesMixin from "@mixins/vacancies";

import CurriculumStage from "@blocks/application/CurriculumStage.vue";
import InscriptionStage from "@blocks/application/InscriptionStage.vue";
import SurveyStage from "@blocks/application/SurveyStage.vue";
import DiscStage from "@blocks/application/DiscStage.vue";

import VacancyDetails from "@blocks/vacancy/VacancyDetails.vue";

import { CheckIcon, XIcon } from "@vue-hero-icons/solid";

import { LockClosed, LockOpen } from "@components/icons";

export default {
  mixins: [companyMixin, vacanciesMixin],
  components: {
    VacancyDetails,
    CurriculumStage,
    InscriptionStage,
    DiscStage,
    SurveyStage,
    CheckIcon,
    XIcon,
    LockClosed,
    LockOpen,
  },
  props: {
    applicationId: {
      type: Number | String,
      required: true,
    },
    sjId: {
      type: Number | String,
      required: false,
    },
  },
  setup({ props }) {
    const applications = useApplications();

    return {
      // you can return the whole store instance to use it in the template
      applications,
    };
  },
  data() {
    return {
      //config: null,
      application: null,
      //id: this.$route.query.id
    };
  },
  computed: {
    collapaseBaseId() {
      return "accordion-" + this._uid;
    },
    collapseBindings() {
      return {
        accordion: "accordion-" + this._uid,
      };
    },
    vacancySubtitle() {
      const values = [];

      if (this.application.vacancy.company_name) {
        values.push(this.application.vacancy.company_name);
      }
      if (this.getLocalization) {
        values.push(vacancyLocalization);
      }

      return values.join(" - ");
    },
    vacancyLocalization() {
      var localization = "";

      if (this.application.vacancy.city) {
        localization +=
          this.application.vacancy.city +
          (this.application.vacancy.state ? ", " : "");
      }

      if (this.application.vacancy.state) {
        localization +=
          this.application.vacancy.state +
          (this.application.vacancy.country ? " - " : "");
      }

      if (this.application.vacancy.country) {
        localization += this.application.vacancy.country;
      }

      return localization;
    },
  },
  created() {
    this.$root.loading = true;

    if (this.company.config.sj) {
      this.applications
        .read(this.sjId)
        .then((data) => {
          this.application = data;
        })
        .finally(() => {
          this.$root.loading = false;
        });
    } else {
      this.applications
        .read(this.applicationId)
        .then((data) => {
          this.application = data;
        })
        .finally(() => {
          this.$root.loading = false;
        });
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "profile-applications" });
    },
  },
};
</script>
