<template>
    <!-- ESTE COMPONENTE RECEBE O CONTEÚDO DO RESULTADO DO TESTE, É O MESMO QUE O CANDIDATO VISUALIZA -->
    <div :class="['disc-profile-header', 'disc-profile-header--' + profile]">
        <disc-profile-icon :profile="profile" />
        <h1>Perfil {{ $t('disc.profile.' + profile) }}</h1>


        <!-- V-IF É A MELHOR FORMA DE FAZER ISSO? -->
        <!-- Resp: Não! É melhor jogar os dados no "data", e inserir aqui o loop -->
        <div class="d-flex">
            <div
                v-for="(tag, index) in pattern.tags"
                :class="{'badge' : true, 'mr-2': index != pattern.tags.length - 1}">
                {{ tag }}
            </div>
        </div>
    </div>
</template>

<script>
import DiscProfileIcon from './DiscProfileIcon.vue';

export default {
    components: {
        DiscProfileIcon,
    },
    props: {
        pattern : {
            type: Object,
            required: true
        },
    },
    computed: {
        profile() {
            return this.pattern.name.toLowerCase();
        }
    }
}
</script>
<style lang="scss">
    // PROFILE COLORS
    $disc-developer-100: #FDDFDD;
    $disc-developer-200: #F73B3B;
    $disc-developer-300: #9B3131;

    $disc-result-oriented-100: #FFDFD6;
    $disc-result-oriented-200: #F45434;
    $disc-result-oriented-300: #AE3F29;

    $disc-inspirational-100: #FDDCC3;
    $disc-inspirational-200: #F96C15;
    $disc-inspirational-300: #9C410D;

    $disc-creative-100: #FFDBEA;
    $disc-creative-200: #F53D7A;
    $disc-creative-300: #82173A;

    $disc-promoter-100: #FFF0B3;
    $disc-promoter-200: #DB9200;
    $disc-promoter-300: #A26607;

    $disc-persuader-100: #FFE9C2;
    $disc-persuader-200: #FA9200;
    $disc-persuader-300: #A35C00;

    $disc-counselor-100: #F5F2A3;
    $disc-counselor-200: #AA980E;
    $disc-counselor-300: #6B5606;

    $disc-appraiser-100: #E4F4BE;
    $disc-appraiser-200: #6DA046;
    $disc-appraiser-300: #415E2B;

    $disc-specialist-100: #D4FAC2;
    $disc-specialist-200: #51BA1C;
    $disc-specialist-300: #2E6B0F;

    $disc-achiever-100: #E5F7B0;
    $disc-achiever-200: #9CA51D;
    $disc-achiever-300: #4F6411;

    $disc-agent-100: #D7F4AE;
    $disc-agent-200: #81A81F;
    $disc-agent-300: #4E6605;

    $disc-investigator-100: #C3F8D4;
    $disc-investigator-200: #1AB74E;
    $disc-investigator-300: #086828;

    $disc-objective-thinker-100: #CCE1FF;
    $disc-objective-thinker-200: #4794EB;
    $disc-objective-thinker-300: #2562A7;

    $disc-perfectionist-100: #BBE9FB;
    $disc-perfectionist-200: #16A2DA;
    $disc-perfectionist-300: #06678E;

    $disc-pratitioner-100: #B6F6F7;
    $disc-pratitioner-200: #13ACAE;
    $disc-pratitioner-300: #066E6F;
    // PROFILE COLORS //

    .disc-profile-header {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 48px 24px;

        background-repeat: no-repeat;

        &::after {
            content: "";
            opacity: .065;
            height: 100%;
            display: block;
            width: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: 1;
            background-size: 5.5%;
            background-position: center;
        }

        h1 {
            font-size: 2.4rem;
            font-weight: bold;
            margin-bottom: 24px;

            @media (max-width: 767.98px) {
                font-size: 1.5rem;
            }
        }

        h2 {
            font-size: 1.6rem;
            font-weight: bold;
        }

        .disc-profile-icon {
            margin-bottom: 24px;
            z-index: 2;
            img {
                width: 110px;
            }
        }

        .badge {
            border-radius: 30px;
            font-size: .8rem;
        }
    }

    .disc-profile-header--Developer {
        background-color: $disc-developer-100;

        &::after {
            background-image: url("@assets/images/disc/developer.svg");
        }

        h1 {
            color: $disc-developer-300;
        }

        h2 {
            color: $disc-developer-200;
        }

        .badge {
            background: $disc-developer-100;
            color: $disc-developer-200;
            border: 1px solid $disc-developer-200;
        }
    }

    .disc-profile-header--Result-oriented {
        background-color: $disc-result-oriented-100;

        &::after {
            background-image: url("@assets/images/disc/result-oriented.svg");
        }

        h1 {
            color: $disc-result-oriented-300;
        }

        h2 {
            color: $disc-result-oriented-200;
        }

        .badge {
            background: $disc-result-oriented-100;
            color: $disc-result-oriented-200;
            border: 1px solid $disc-result-oriented-200;
        }
    }

    .disc-profile-header--Inspirational {
        background-color: $disc-inspirational-100;

        &::after {
            background-image: url("@assets/images/disc/inspirational.svg");
        }

        h1 {
            color: $disc-inspirational-300;
        }

        h2 {
            color: $disc-inspirational-200;
        }

        .badge {
            background: $disc-inspirational-100;
            color: $disc-inspirational-200;
            border: 1px solid $disc-inspirational-200;
        }
    }

    .disc-profile-header--Creative {
        background-color: $disc-creative-100;

        &::after {
            background-image: url("@assets/images/disc/creative.svg");
        }

        h1 {
            color: $disc-creative-300;
        }

        h2 {
            color: $disc-creative-200;
        }

        .badge {
            background: $disc-creative-100;
            color: $disc-creative-200;
            border: 1px solid $disc-creative-200;
        }
    }

    .disc-profile-header--Promoter {
        background-color: $disc-promoter-100;

        &::after {
            background-image: url("@assets/images/disc/promoter.svg");
        }

        h1 {
            color: $disc-promoter-300;
        }

        h2 {
            color: $disc-promoter-200;
        }

        .badge {
            background: $disc-promoter-100;
            color: $disc-promoter-200;
            border: 1px solid $disc-promoter-200;
        }
    }

    .disc-profile-header--Persuader {
        background-color: $disc-persuader-100;

        &::after {
            background-image: url("@assets/images/disc/persuader.svg");
        }

        h1 {
            color: $disc-persuader-300;
        }

        h2 {
            color: $disc-persuader-200;
        }

        .badge {
            background: $disc-persuader-100;
            color: $disc-persuader-200;
            border: 1px solid $disc-persuader-200;
        }
    }

    .disc-profile-header--Counselor {
        background-color: $disc-counselor-100;

        &::after {
            background-image: url("@assets/images/disc/counselor.svg");
        }

        h1 {
            color: $disc-counselor-300;
        }

        h2 {
            color: $disc-counselor-200;
        }

        .badge {
            background: $disc-counselor-100;
            color: $disc-counselor-200;
            border: 1px solid $disc-counselor-200;
        }
    }

    .disc-profile-header--Appraiser {
        background-color: $disc-appraiser-100;

        &::after {
            background-image: url("@assets/images/disc/appraiser.svg");
        }

        h1 {
            color: $disc-appraiser-300;
        }

        h2 {
            color: $disc-appraiser-200;
        }

        .badge {
            background: $disc-appraiser-100;
            color: $disc-appraiser-200;
            border: 1px solid $disc-appraiser-200;
        }
    }

    .disc-profile-header--Specialist {
        background-color: $disc-specialist-100;

        &::after {
            background-image: url("@assets/images/disc/specialist.svg");
        }

        h1 {
            color: $disc-specialist-300;
        }

        h2 {
            color: $disc-specialist-200;
        }

        .badge {
            background: $disc-specialist-100;
            color: $disc-specialist-200;
            border: 1px solid $disc-specialist-200;
        }
    }

    .disc-profile-header--Achiever {
        background-color: $disc-achiever-100;

        &::after {
            background-image: url("@assets/images/disc/achiever.svg");
        }

        h1 {
            color: $disc-achiever-300;
        }

        h2 {
            color: $disc-achiever-200;
        }

        .badge {
            background: $disc-achiever-100;
            color: $disc-achiever-200;
            border: 1px solid $disc-achiever-200;
        }
    }

    .disc-profile-header--Agent {
        background-color: $disc-agent-100;

        &::after {
            background-image: url("@assets/images/disc/agent.svg");
        }

        h1 {
            color: $disc-agent-300;
        }

        h2 {
            color: $disc-agent-200;
        }

        .badge {
            background: $disc-agent-100;
            color: $disc-agent-200;
            border: 1px solid $disc-agent-200;
        }
    }

    .disc-profile-header--Investigator {
        background-color: $disc-investigator-100;

        &::after {
            background-image: url("@assets/images/disc/investigator.svg");
        }

        h1 {
            color: $disc-investigator-300;
        }

        h2 {
            color: $disc-investigator-200;
        }

        .badge {
            background: $disc-investigator-100;
            color: $disc-investigator-200;
            border: 1px solid $disc-investigator-200;
        }
    }

    .disc-profile-header--Objective {
        background-color: $disc-objective-thinker-100;

        &::after {
            background-image: url("@assets/images/disc/objective-thinker.svg");
        }

        h1 {
            color: $disc-objective-thinker-300;
        }

        h2 {
            color: $disc-objective-thinker-200;
        }

        .badge {
            background: $disc-objective-thinker-100;
            color: $disc-objective-thinker-200;
            border: 1px solid $disc-objective-thinker-200;
        }
    }

    .disc-profile-header--Perfectionist {
        background-color: $disc-perfectionist-100;

        &::after {
            background-image: url("@assets/images/disc/perfectionist.svg");
        }

        h1 {
            color: $disc-perfectionist-300;
        }

        h2 {
            color: $disc-perfectionist-200;
        }

        .badge {
            background: $disc-perfectionist-100;
            color: $disc-perfectionist-200;
            border: 1px solid $disc-perfectionist-200;
        }
    }

    .disc-profile-header--Pratitioner {
        background-color: $disc-pratitioner-100;

        &::after {
            background-image: url("@assets/images/disc/practitioner.svg");
        }

        h1 {
            color: $disc-pratitioner-300;
        }

        h2 {
            color: $disc-pratitioner-200;
        }

        .badge {
            background: $disc-pratitioner-100;
            color: $disc-pratitioner-200;
            border: 1px solid $disc-pratitioner-200;
        }
    }
</style>
