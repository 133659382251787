<template>
    <div>
        <span v-if="step.status == 2">
            {{ step.content }}
        </span>
        <span v-else-if="step.status == 1">

            <CompanyForm v-bind="step.form" />
        </span>
    </div>
</template>
<script>
import CompanyForm from "@blocks/company/Form.vue";
//import { useCompanyForm } from "@state/company/form";

export default {
    components : {
        CompanyForm
    },
    props : {
        step : {
            type: Object,
            required: true
        }
    }
}
</script>
