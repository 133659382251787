<template>
  
  <div class="vacancy-card card">
    <div class="card-body p-4">
      <div class="d-flex flex-column gap-2">
        <h5>{{ title }}</h5>
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            href="#"
            v-for="(quantity, index) in items"
            :key="index"
            @click="search(index, title)"
          >
            {{ index }}
            <b-badge variant="primary" pill>{{ quantity }}</b-badge>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>

</template>

<script>
import * as _ from "lodash-es";
import { BListGroup } from "bootstrap-vue";
import companyMixin from "@state/company";
import { useVacancies } from "@state/vacancy/vacancies";

export default {
  mixins: [companyMixin],
  components: {
    BListGroup,
  },
  setup() {
    const vacancies = useVacancies();

    return {
      vacancies,
    };
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    items: {
      required: true,
      // type: Object,
    },
  },
  computed: {},
  methods: {
    search(condition, title) {
      // TODO a more elegant way to search this
      if (title == "Vagas por cidade" && condition !== "Todas") {
        this.vacancies.conditions.location = condition;

        this.$router.push({
          name: "vacancy-search",
          query: {
            text: "",
            location: this.vacancies.conditions.location,
          },
        });
      } else if (condition !== "Todas") {
        this.vacancies.conditions.text = condition;

        this.$router.push({
          name: "vacancy-search",
          query: {
            text: this.vacancies.conditions.text,
            location: null,
          },
        });
      } else {
        this.$router.push({
          name: "vacancy-search",
          query: {
            text: null,
            location: null,
          },
        });
      }
    },
  },
};
</script>