<template>
  <div class="" v-if="!isLoading">
    <changeable-status
      :state="isSaving"
      :counter="changeCounter"
      v-if="showStatus"
      fixed
    />

    <div class="d-flex justify-between align-items-center mb-8">
      <h2 class="text-3xl font-weight-bold">{{ $t("curriculum.title") }}</h2>
    </div>

    <validation-observer ref="profileRules" tag="div" class="">
      <b-card class="" header-class="with-tabs sticky-top" body-class="p-0">
        <template #header>
          <b-nav
            card-header
            tabs
            class="flex-nowrap align-items-center"
            :class="{ 'overflow-x-scroll': isMobile() }"
          >
            <b-nav-item
              v-for="section in formSections"
              :href="'#' + section.name"
              :key="section.name"
              :class="{ active: visibles[section.name] }"
            >
              {{ $t(section.title) }}
            </b-nav-item>
          </b-nav>
        </template>

        <div class="p-6">
          <validation-observer
            v-for="section in formSections"
            ref="profileInnerRules"
            :key="section.name"
          >
            <div
              class="mb-1 form-section"
              :data-cy="section.name"
              v-if="section.show"
              :id="section.name"
              v-b-visible="(state) => handleVisible(state, section.name)"
            >
              <component
                :section="section"
                @errorsChanged="updateErrors"
                v-bind:is="section.component"
                :ref="section.name"
              />
              <!-- v-observe-visibility="{
                                    callback: handleVisibility,
                                    intersection: {
                                        rootMargin: '16px',
                                        threshold: 0.3,
                                    }
                                }" -->
            </div>
          </validation-observer>
          <div class="pb-4 pt-4" style="display: grid">
            <b-button variant="primary" class="btn" @click="save" pill>
              {{ $t("common.save") }}
            </b-button>
          </div>
        </div>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import * as _ from "lodash-es";

import { ValidationObserver } from "vee-validate";
import { VBToggle, VBVisible } from "bootstrap-vue";
//import { ObserveVisibility } from 'vue-observe-visibility'

import Personal from "./curriculum/personal/Personal.vue";
import Professional from "./curriculum/professional/Professional.vue";
import Education from "./curriculum/education/Education.vue";

import OtherInformation from "./curriculum/additional/OtherInformation.vue";

import ChangeableStatus from "@components/changeable/Status.vue";

import companyMixin from "@state/company";

import * as FormComponents from "@components/forms";
import { useCurriculum } from "@state/user/curriculum";
import { useDomain } from "@state/domain/domain";
import { useCompanyForm } from "@state/company/form";
import { useChangeable } from "@state/behaviours/changeable";
import { useTheme } from "@state/system/theme";

import { ChevronLeftIcon, ChevronRightIcon } from "@vue-hero-icons/outline";

export default {
  mixins: [companyMixin],
  components: {
    ValidationObserver,
    ...FormComponents,
    ChangeableStatus,
    Personal,
    Professional,
    Education,
    OtherInformation,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  setup({ context }) {
    const forms = useCompanyForm();
    const curriculum = useCurriculum();
    const domain = useDomain();
    const changeable = useChangeable();
    const theme = useTheme();

    forms.load("curriculum");

    return {
      // you can return the whole store instance to use it in the template
      forms,
      curriculum,
      domain,
      changeable,
      isMobile: theme.isMobile,
    };
  },
  directives: {
    "b-toggle": VBToggle,
    //"b-scrollspy": VBScrollspy,
    "b-visible": VBVisible,
    //"observe-visibility" : ObserveVisibility
  },
  props: {},
  data() {
    return {
      isLoading: true,
      changeCounter: 0,
      isSaving: false,
      showStatus: false,
      stateSubscribe: null,

      config: {},
      profile: {},
      formValidation: { hasErrors: false, completedPercentage: 100 },
      componentKey: 0,
      collapses: [false, false, false, false],
      visibles: {
        personal: false,
        professional: false,
        education: false,
        other_information: false,
      },
    };
  },
  computed: {
    formSections() {
      return _.pickBy(this.forms.curriculum.sections, "show");
    },
  },
  created() {
    //this.$root.$on("bv::scrollspy::activate", this.onActivate);
    this.isLoading = true;
    this.$root.loading = true;

    this.curriculum
      .read()
      .then(() => {
        this.isLoading = false;

        this.$nextTick(() => {
          this.$root.loading = false;
          this.$watch("curriculum.address.zip_code", (zipcode) => {
            this.emittedByZipCodeField = true;
            this.curriculum
              .setAddressBasedOnZipcode(zipcode)
              .then((response) => {
                if (response) {
                  this.curriculum.address.street_type = response.street_type;
                  this.curriculum.address.street = response.street;
                  this.curriculum.address.district = response.district;
                  this.curriculum.address.country = response.country;
                  this.curriculum.address.state = response.state;

                  // NECESSARY BECAUSE THE CITIES LIST MUST BE LOADED FIRST
                  this.$nextTick(() => {
                    this.curriculum.address.city = response.city;

                    this.curriculum.persist({
                      address: {
                        street_type: response.street_type,
                        street: response.street,
                        district: response.district,
                        country: response.country,
                        state: response.state,
                        city: response.city,
                        latitude: response.latitude,
                        longitude: response.longitude,
                      },
                    });
                  });
                } else {
                  this.$refs.profileRules.setErrors({
                    "curriculum.address.zip_code": [
                      "O campo cep possui um formato inválido",
                    ],
                  });

                  this.curriculum.address.street_type = null;
                  this.curriculum.address.street = null;
                  this.curriculum.address.district = null;
                  this.curriculum.address.country = null;
                  this.curriculum.address.state = null;
                  // NECESSARY BECAUSE THE CITIES LIST MUST BE LOADED FIRST
                  this.$nextTick(() => {
                    this.curriculum.address.city = null;
                  });
                }
              })
              .finally(() => (this.emittedByZipCodeField = false));
          });

          this.$watch("curriculum.address.state", (uf) => {
            if (!this.emittedByZipCodeField) {
              this.curriculum.address.city = "";
            }
            this.domain.load(`cities/${uf}`, "cities");
          });
        });
      })
      .catch(() => {});
  },
  mounted() {
    if (_.isEmpty(this.$auth.user().cpf)) {
      this.$router.push({
        name: "complete-registration",
      });
    }

    if (this.$auth.user().need_to_change_password) {
      this.$router.push({
        name: "register4",
        params: { change_password: true },
      });
    }

    this.stateSubscribe = this.changeable.$subscribe((mutation, state) => {
      const poll_changes = this.changeable.poolChanges("curriculum");

      this.changeCounter = poll_changes.counter;

      if (
        poll_changes.counter >= 1 &&
        !this.$auth.user().need_to_change_password &&
        !_.isEmpty(this.$auth.user().cpf)
      ) {
        this.showStatus = true;
        // SAVE PARTIAL CURRICULUM
        this.isSaving = true;

        this.curriculum
          .persist(poll_changes.changes)
          .then(({ data }) => {
            if (data.success) {
              this.changeable.cleanUpAfterSave();
              this.changeCounter = 0;
            }
            this.isSaving = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.showStatus = false;
            }, 1800);
            this.$root.loading = false;
            this.isSaving = false;
          });
      }
    });
  },

  destroyed() {
    this.stateSubscribe();
  },

  methods: {
    handleVisible(state, section_id) {
      this.visibles[section_id] = state;
      //this.visibles[entry.target.dataset.section] = state;
    },
    handleVisibility(state, entry) {},
    // onActivate(target) {
    //     console.log(
    //         'Received event: "bv::scrollspy::activate" for target ',
    //         target
    //     );
    //     document.getElementById("page-component").scrollTop = 0;
    // },
    validate(save) {
      return new Promise((resolve, reject) => {
        window.profileObs = this.$refs.profileRules;
        this.$refs.profileRules.validate().then((success) => {
          if (success) {
            if (save) {
              this.$service.curriculum.save(this.profile);
            }
            resolve(success);
          } else {
            this.scrollToError();
            reject(false);
          }
        });
      });
    },
    save(e) {
      this.$refs.profileRules.validate().then((success) => {
        this.curriculum
          .setAddressBasedOnZipcode(this.curriculum.address.zip_code)
          .then((response) => {
            if (!response) {
              this.$refs.profileRules.setErrors({
                "curriculum.address.zip_code": [
                  "O campo cep possui um formato inválido",
                ],
              });

              this.curriculum.address.street_type = null;
              this.curriculum.address.street = null;
              this.curriculum.address.district = null;
              this.curriculum.address.country = null;
              this.curriculum.address.state = null;
              // NECESSARY BECAUSE THE CITIES LIST MUST BE LOADED FIRST
              this.$nextTick(() => {
                this.curriculum.address.city = null;
              });

              return;
            }
          })
          .finally(() => (this.emittedByZipCodeField = false));

        if (!success) {
          this.inputFocus();
          this.isSaving = false;
          return;
        }

        const poll_changes = this.changeable.poolChanges("curriculum");
        this.changeCounter = poll_changes.counter;

        // SAVE PARTIAL CURRICULUM
        this.isSaving = true;
        this.showStatus = true;
        this.curriculum.persist(poll_changes.changes).then(({ data }) => {
          if (data.success) {
            this.changeable.cleanUpAfterSave();
            this.changeCounter = 0;
          }

          this.$router.push({ name: "profile" });
          this.isSaving = false;

          setTimeout(() => {
            this.showStatus = false;
          }, 2000);
        });
      });
    },
    inputFocus() {
      this.$nextTick(() => {
        const el = this.$el.querySelector(".is-invalid");
        el.scrollIntoView();
        return;
      });
    },

    scrollToError() {
      for (var i in this.$refs.profileRules.observers) {
        var observer = this.$refs.profileRules.observers[i];

        var field = _.chain(observer.errors)
          .mapValues((item) => item.length > 0)
          .pickBy((item) => item)
          .keys()
          .head()
          .value();

        if (field) {
          this.collapses[i] = true;
          this.$nextTick(() => {
            let el = document.getElementsByName(field)[0];
            if (el) {
              window.setTimeout(() => {
                el.scrollIntoView({
                  block: "start",
                  behavior: "smooth",
                });
              }, 500);
            }
          });
          break;
        }
      }
    },

    updateErrors() {
      const errors = Object.values(this.sectionErrors).filter(
        (s) => s === true
      ).length;

      this.$set(
        this.formValidation,
        "completedPercentage",
        ((config.sections.length - errors) / config.sections.length) * 100
      );

      this.$forceUpdate();

      this.componentKey++;
    },
    formUpdated(newV, oldV) {},
    goToSection(name) {},
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "complete-registration" || to.name == "register4") {
      next();
    } else {
      this.$refs.profileRules.validate().then((success) => {
        if (!success) {
          const answer = window.confirm(
            "Existem campos obrigatórios não preenchidos, realmente deseja sair?"
          );

          if (answer) {
            next();
          } else {
            this.inputFocus();
            next(false);
          }
        } else {
          next();
        }
      });
    }
  },
  watch: {},
};
</script>

<style lang="scss">
.profile-subsection {
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .profile-form {
    padding: 1rem !important;
  }

  .wizard-tab-content {
    padding: 30px 0 20px !important;
  }
}

@media (min-width: 1200px) {
  .profile-form {
    padding: 0;

    .vs-progress--background {
      width: 20vw !important;
    }

    .modal-page-body {
      width: 50vw;
      margin-left: 25vw;
    }

    .modal-footer-actions {
      button {
        width: 150px !important;
      }
    }
  }
}

.button-div {
  width: 100%;
  text-align: center;
  position: relative !important;

  button {
    width: 100% !important;
    border-radius: 5px;
  }
}

.profile-form {
  padding: 40px;

  h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  h2.profile-page-title {
    font-size: 1.2rem;
    margin-left: 10px;
  }

  .vs-collapse-item {
    margin-bottom: 15px;
  }

  .progress-div {
    text-align: center;

    .vs-progress--background {
      height: 7px;
      width: 70vw;
    }
  }

  .success-icon {
    color: #1bbb61;
    margin-right: 5px;
  }

  .error-icon {
    color: #dc3545;
    margin-right: 5px;
  }

  .vs-collapse-item.card {
    color: #2d3953;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border: none;

    .icon-header i {
      font-size: 1.5rem;
    }
  }

  .vs-input--label {
    font-size: 16px;
    font-weight: bold;
    color: #435d6b;
  }

  input[type="text"],
  .vs-textarea,
  .b-form-spinbutton {
    border: 1px solid #abd3e8 !important;
    color: #2d3953;
  }

  .vs-radio--label {
    color: #2d3953;
  }
}
</style>
