<template>
  <section id="sobre" data-scroll-to="rich-text">
    <h4 class="mb-4" v-if="title">{{ title }}</h4>

    <p>{{ text }}</p>

    <div class="embed-container" v-if="videoUrl">
      <iframe :src="videoUrl" :alt="subtitle" frameborder="0" allowfullscreen></iframe>
    </div>
    <div v-else-if="imageUrl">
      <img :src="imagesList[imageUrl]" :alt="subtitle" style="height: 100%; width: 100%; object-fit: contain;"/>
    </div>
  </section>
</template>

<script>

import images from '@mixins/images';

export default {
  mixins: [images],
  props: {
    title: {
      type: String,
      required: false,
    },
    text: {
      required: true,
    },
    videoUrl: {
      type: String,
      required: false,
    },
    imageUrl: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
