<template>
    <div>
        <span v-if="step.status == 2">
            {{ step.content }}
        </span>
        <span v-else-if="step.status == 1">
            
            {{ step.content }}

            <router-link :to="{ name: step.link.name, params : step.link.params }" v-slot="{ navigate, href, isExactActive }">
                <a :href="href" @click="navigate" :class="{ 'active': isExactActive }">
                    <span>{{ step.link.text }}</span>
                </a>
            </router-link>
        </span>
    </div>
</template>
<script>
export default {
    props : {
        step : {
            type: Object,
            required: true
        }
    },
}
</script>