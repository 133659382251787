<template>
  <div class="container mt-3 mt-lg-20">
    <!-- <disc-profile-description :pattern="developer"><disc-profile-description> -->

    <!-- <template v-if="$service.browserSupport"> -->
    <template v-if="!isLoading">
      <b-row vs-type="flex" vs-justify="center">
        <b-col vs-xs="12" vs-lg="7">
          <div class="disq-wrap">
            <form-wizard
              v-if="!testStarted && !testFinished"
              ref="pre_wizard"
              color="transparent"
              :title="null"
              :subtitle="null"
              :hideButtons="true"
              finishButtonText="Submit"
            >
              <!-- @on-change="validateCard" -->

              <span slot="step"></span>
              <!-- A BARRA DE PROGRESSO DEVERIA APRECER APENAS QUANDO O TESTE COMEÇA DE FATO -->

              <!-- OLÁ, USUÁRIO -->
              <tab-content>
                <div class="text-center">
                  <img
                    :src="mirrorSvg"
                    class="mb-4"
                    style="max-width: 300px"
                    alt="Pessoa se olhando no espelho"
                  />
                  <div class="h3 font-bold text-dark mb-3">
                    {{ $t("disc.form.hello_user", [$auth.user().name]) }}
                  </div>
                  <div class="mb-4 no-copy">
                    <p>
                      A atividade a seguir pode te ajudar a se conhecer ainda
                      mais.
                    </p>
                    <!-- <p>{{ $t('you-are-here-to-discover-your-behavioral-disc-profile') }}!</p> -->
                  </div>
                  <b-button
                    variant="primary"
                    class="next-button"
                    @click="goToNextTab()"
                    pill
                  >
                    Próximo
                  </b-button>
                  <!-- <div class="mt-3">
                                        <a href="javascript:void(0)" @click.prevent="startTest()" class="text-dark small"><u>{{ $t('skip-and-start-the-test' ) }}</u></a>
                                    </div> -->
                </div>
              </tab-content>
              <!-- OLÁ, USUÁRIO -->

              <!-- AQUI NÃO TEM RESPOSTA ERRADA -->
              <tab-content>
                <div class="text-center">
                  <img
                    :src="improvemindSvg"
                    class="mb-4"
                    style="max-width: 300px"
                    alt=""
                  />
                  <div class="h3 font-bold text-dark mb-3">
                    Aqui não há certo ou errado
                  </div>
                  <div class="mb-4 no-copy">
                    <p>
                      Ao finalizar, você verá um retrato atual do seu perfil e
                      pode usar isto para desenvolver suas habilidades
                      comportamentais.
                    </p>
                  </div>
                  <b-button
                    variant="primary"
                    class="next-button"
                    @click="goToNextTab()"
                    pill
                  >
                    Próximo
                  </b-button>
                  <!-- <div class="mt-3">
                                        <a href="javascript:void(0)" @click.prevent="s   ext-dark small"><u>{{ $t('skip-and-start-the-test' ) }}</u></a>
                                    </div> -->
                </div>
              </tab-content>
              <!-- AQUI NÃO TEM RESPOSTA ERRADA -->

              <!-- NEM RESULTADO NEGATIVO -->
              <!-- <tab-content >
                                <div class="text-center">
                                    <div class="text-center mb-4">
                                        <img :src="require(`@assets/images/disc/logo.svg`)" class="d-block mx-auto mb-2" style="max-width:300px" alt="Selecty">
                                        <i class="text-sm">Busque empresas que usam o Selecty</i>
                                    </div>
                                    <div class="h3 font-bold text-dark mb-3">
                                    O resultado é válido por 6 meses
                                    </div>
                                    <div class="mb-4 no-copy">
                                        <p>Dentro desse período, você <b>não precisa repetir a atividade</b> ao se candidatar em vagas de empresas que usam o <b>Selecty</b>.</p>
                                    </div>
                                    <vs-button color="primary" type="filled" @click="goToNextTab()">Próximo</vs-button>

                                </div>
                            </tab-content> -->
              <!-- NEM RESULTADO NEGATIVO -->

              <!-- INSTRUÇÕES -->
              <tab-content>
                <div class="text-center">
                  <img
                    :src="discExampleSvg"
                    class="mb-4 w-100 h-100"
                    style="max-width: 340px"
                    alt="Um balão de fala com um ponto de interrogação"
                  />
                  <div class="h3 font-bold text-dark mb-3">Instruções</div>
                  <div class="mb-4 no-copy">
                    <p>
                      Para cada grupo de palavras, escolha a que
                      <b>mais combina com você</b> e a que
                      <b>menos combina com você</b>.
                    </p>
                  </div>
                  <b-button
                    variant="primary"
                    class="next-button"
                    @click="startTest()"
                    pill
                  >
                    {{ $t("disc.form.start") }}
                  </b-button>
                  <!-- <div class="mt-3">
                                        <a href="javascript:void(0)" @click.prevent="startTest()" class="text-dark small"><u>{{ $t('skip-and-start-the-test' ) }}</u></a>
                                    </div> -->
                </div>
              </tab-content>
              <!-- INSTRUÇÕES -->

              <!-- VAMOS COMEÇAR? -->
              <!-- <tab-content >
                                <div class="text-center text-lg">
                                        <img :src="require(`@assets/images/disc/disc-start.svg`)" class="mb-4" style="max-width:300px" alt="Um círculo com um ícone de tocar">
                                        <div class="h3 font-bold text-dark mb-3">
                                        {{ $t('lets-begin') }}?
                                        </div>
                                        <div class="mb-4 no-copy">
                                            <p>{{ $t('see-more-instructions-next') }}</p>
                                        </div>
                                        <vs-button color="primary" type="filled" @click="startTest()">{{ $t('video-interview.start' ) }}</vs-button>
                                </div>
                            </tab-content>  -->
              <!-- VAMOS COMEÇAR? -->

              <!-- FALTA POUCO (INSERIR APÓS A METADE DO TESTE) -->
              <!--
                            <tab-content >
                                <div class="text-center text-lg">
                                        <img :src="require(`@assets/images/disc/goal.svg`)" class="mb-4" style="max-width:300px" alt="Um personagem alcançando uma estrela">
                                        <div class="h3 font-bold text-dark mb-3">
                                        {{ $t('almost-there') }}!
                                        </div>
                                        <div class="mb-4 no-copy">
                                            <p>{{ $t('youve-completed-more-than-half-of-the-test') }}</p>
                                        </div>
                                        <vs-button color="primary" type="filled" @click.prevent="startTest()">Próximo</vs-button>
                                </div>
                            </tab-content>
                        -->
              <!-- FALTA POUCO -->
            </form-wizard>

            <!-- <div
                        v-show="testStarted && !testFinished"
                        class="mb-3 mt-2 mx-auto text-small"
                        style="max-width:450px"> -->
            <!-- <h3>
                            {{ $t('disc_behavioural_profile') }}
                            </h3> -->
            <!-- <span>Para cada grupo de palavras, escolha a que <b>mais combina com você</b> e a que <b>menos combina com você</b>.</span> -->
            <!-- <span>Escolha a palavra que <span class="text-darkgreen"><b>mais combina</b></span> com você e a palavra que <span class="text-darkred"><b>menos combina</b></span> com você. </span> -->
            <!-- </div>                            -->

            <form-wizard
              v-show="testStarted && !testFinished"
              ref="form_wizard"
              color="rgba(var(--vs-success), 1)"
              class="mt-3"
              :title="null"
              :subtitle="null"
              finishButtonText="Submit"
              @on-change="onChangeTabContent"
            >
              <!-- A BARRA DE PROGRESSO DEVERIA APRECER APENAS QUANDO O TESTE COMEÇA DE FATO -->
              <span slot="step"></span>
              <!-- A BARRA DE PROGRESSO DEVERIA APRECER APENAS QUANDO O TESTE COMEÇA DE FATO -->

              <tab-content
                :id="index"
                :key="index"
                v-for="(question, index, counter) in discState.test.questions"
              >
                <DiscQuestion
                  :terms="question.choices"
                  ref="answer"
                  :key="'answer-' + index"
                  v-model="response[index]"
                  :max-time="question.max_time"
                  :question-index="index"
                  @answer:change="onAnswerChange"
                />
              </tab-content>

              <!-- BOTÕES: PRECISA VER A QUESTÃO DE VALIDAÇÃO ETC -->
              <template slot="footer" slot-scope="props">
                <div class="text-center mt-3">
                  <template v-if="testStarted">
                    <b-button
                      v-if="props.activeTabIndex > 0 && !props.isLastStep"
                      @click="props.prevTab()"
                      variant="primary"
                      type="border"
                      class="mr-2"
                      pill
                    >
                      Voltar
                    </b-button>
                    <!-- BOTÃO INATIVO -->
                    <!-- <vs-button disabled color="primary" type="filled">Próximo</vs-button> -->
                    <!-- FIM DO BOTÃO INATIVO -->
                    <b-button
                      class="next-question"
                      v-if="!props.isLastStep"
                      @click="props.nextTab()"
                      variant="primary"
                      :disabled="!canDoNextStep"
                      pill
                    >
                      Próximo
                    </b-button>
                    <b-button
                      class="finish-test"
                      v-else
                      @click="finishTest()"
                      :disabled="!canDoNextStep"
                      variant="success"
                      pill
                    >
                      Ver resultado
                    </b-button>
                  </template>
                </div>
              </template>
              <!-- FIM DOS BOTÕES -->
            </form-wizard>
            <!--
                        <form-wizard
                            v-if="testStarted && testFinished"
                            ref="pos_wizard"
                            color="transparent"
                            :title="null"
                            :subtitle="null"
                            :hideButtons="true"
                            finishButtonText="Submit"
                            >

                            <span slot="step"></span>

                            <tab-content>
                                <div class="h4 font-bold text-dark mb-3">
                                    {{ $t('How_was_the_test?') }}
                                </div>
                                <Feedback @feedback="sendFeedback" @feedbackText="sendFeedbackText" :maxOptions="4" />
                            </tab-content>

                            <tab-content>
                                <div class="text-center text-lg">
                                        <img :src="require(`@assets/images/disc/thanks-thumbs-up.svg`)" class="mb-4" style="max-width:300px" alt="Quatro círculos com as letras D, I, S, C saindo de um ícone de cabeça">
                                        <div class="h3 font-bold text-dark mb-3">
                                        {{ $t('thanks_for_your_feedback') }}
                                        </div>
                                        <div>
                                            <p>{{ $t('we_will_do_our_best_to_improve_your_user_experience') }}</p>
                                        </div>
                                </div>
                            </tab-content>
                        </form-wizard>
                        -->
          </div>
        </b-col>
      </b-row>
    </template>
    <!--
        <template v-else>
            <div class="text-center" style="margin-top: 10%;">

                <div class="h3 font-bold text-dark mb-3">
                    Atenção!
                </div>
                <div class="mb-4 no-copy">
                    <p>
                        Infelizmente seu Browser não suporta o teste DISC!
                    </p>
                </div>
            </div>
        </template> -->
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
//import Feedback from '@components/feedback/Feedback'
// import DiscProfileHeader from '@components/disc/DiscProfileHeader'
// import DiscProfileDescription from '@components/disc/DiscProfileDescription'
import improvemindSvg from "@assets/images/disc/improvemind.svg";
import discExampleSvg from "@assets/images/disc/disc-example.gif";
import mirrorSvg from "@assets/images/disc/mirror.svg";
import { useDisc } from "@state/evaluation/disc";

import DiscQuestion from "./questions/DiscQuestion.vue";

import EvaluationMixin from "@mixins/evaluation";
import * as _ from "lodash-es";
export default {
  mixins: [EvaluationMixin],
  components: {
    FormWizard,
    TabContent,
    //Feedback,
    DiscQuestion,
  },
  setup() {
    const discState = useDisc();
    //const vacancies = useVacancies();

    return {
      // you can return the whole store instance to use it in the template
      discState,
      improvemindSvg,
      discExampleSvg,
      mirrorSvg,
    };
  },
  data() {
    return {
      isLoading: true,
      testStarted: false,
      testFinished: false,

      currentQuestionIndex: null,

      form_errors: {},
      questions: {},
      response: {},
      canDoNextStep: false,
    };
  },
  computed: {
    selectiveProcessId() {
      return this.$route.params.selectiveProcessId;
    },
    questionKeys() {
      return Object.keys(this.questions);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    startTest() {
      this.testStarted = true;

      window.addEventListener("beforeunload", this.unloadFunction.bind(this));
      window.removeEventListener(
        "beforeunload",
        this.unloadFunction.bind(this)
      );

      this.currentQuestionIndex = _.first(Object.keys(this.questions));
      this.discState.startQuestion(this.currentQuestionIndex);
      this.onChangeTabContent(-1, 0);
    },

    unloadFunction(e) {
      var confirmationMessage = "o/";

      e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    },

    finishTest() {
      this.$root.loading = true;

      this.discState.finishTest(this.response).then((values) => {
        if (this.selectiveProcessId) {
          this.$router.push({
            name: "evaluation-disc-results",
            params: {
              requestId: this.discState.id,
              selectiveProcessId: this.selectiveProcessId,
            },
          });
        } else {
          this.$router.push({
            name: "evaluation-disc-results",
            params: { requestId: this.discState.id },
          });
        }
      });

      //this.$http.post('/evaluation/disc/answers', response).then(({ data }) => {

      //});
    },

    async onChangeTabContent(prev, next) {
      this.canDoNextStep = false;

      console.warn("questions", this.questionKeys, prev, next);

      //Atualiza index da questão atual
      this.$set(this, "currentQuestionIndex", this.questionKeys[next]);

      if (next > prev) {
        //INDO PRA FRENTE
        if (prev >= 0) {
          this.discState.finishQuestion(this.questionKeys[prev]);
        }
        this.discState.startQuestion(this.questionKeys[next]);
      } else if (prev > next) {
        //INDO PRA TRÁS
        //this.$service.finishQuestion(this.questionKeys[next]);
      }

      //@TODO Arrumar essa gambii, a input dentro do componente vs-radio não seta o valor ao voltar e avançar
      /*
                let leastValue = this.response[this.currentQuestionIndex].least.value;
                let mostValue = this.response[this.currentQuestionIndex].most.value;

                if(leastValue && mostValue) {
                    this.$set(this.response[this.currentQuestionIndex].most, 'value', 'X');
                    this.$set(this.response[this.currentQuestionIndex].least, 'value', 'X');

                    await setTimeout(() => {
                    }, 1000);

                    this.$set(this.response[this.currentQuestionIndex].most, 'value', mostValue);
                    this.$set(this.response[this.currentQuestionIndex].least, 'value', leastValue);

                    this.canDoNextStep = true;
                }
                */
    },

    identifySameValuesOnChoices(questions) {
      //identifica valores que são #, para fazer um tratamento, pois nesse caso o v-model vai bugar caso seja o mesmo valor

      _.map(questions, (item) => {
        if (_.filter(item.choices, { most: "#" }).length > 1) {
          _.map(item.choices, (item_choice) => {
            if (item_choice.most == "#") {
              item_choice.most = "#" + item_choice.term;
            }
          });
        }

        if (_.filter(item.choices, { least: "#" }).length > 1) {
          _.map(item.choices, (item_choice) => {
            if (item_choice.least == "#") {
              item_choice.least = "#" + item_choice.term;
            }
          });
        }
      });

      return questions;
    },

    fetchData() {
      this.$root.loading = true;

      this.isLoading = true;
      this.discState.selective_process_id = this.selectiveProcessId ?? "";

      this.discState.load("disc").then(({ data }) => {
        this.discState.doTimer("evaluation.opened");

        this.test = this.discState.test;

        if (!this.test.can_answer_disc_test) {
          this.$router.push({
            name: "evaluation-disc-denied",
            params: { requestId: this.discState.id },
          });
          return;
        }

        this.questions = this.identifySameValuesOnChoices(this.test.questions);

        //this.questions = data;

        for (var i in this.discState.test.questions) {
          this.$set(this.response, i, {
            most: {
              option: null,
              value: null,
            },
            least: {
              option: null,
              value: null,
            },
          });
          this.$set(this.form_errors, i, false);
        }

        this.isLoading = false;
        this.$root.loading = false;
        this.$nextTick(() => {
          this.$refs.form_wizard.reset();
        });
      });
    },
    onAnswerChange(valid, questionIndex, value) {
      this.canDoNextStep = valid;

      if (valid) {
        this.discState.saveQuestion(questionIndex, value);
      }
    },
    sendFeedback(value) {
      console.log(value);
    },
    sendFeedbackText(value) {
      console.log(value);
    },
    goToNextTab() {
      //this.sessionManager.stopPublishing();
      this.$refs.pre_wizard.nextTab();
    },
    validate(ev) {
      let type = ev.target.getAttribute("option-type");
      let term = ev.target.getAttribute("option-term");
      let index = ev.target.getAttribute("option-index");
      let response = this.response["question" + (parseInt(index) + 1)];

      response[type].option = term;
      if (!_.isNull(response.most.value) && !_.isNull(response.least.value)) {
        if (response.most.option == response.least.option) {
          if (type == "most") {
            response.least.value = null;
            response.least.option = null;
          } else {
            response.most.value = null;
            response.most.option = null;
          }
          this.form_errors[index + 1] = true;
          return (this.canDoNextStep = false);
        }
        this.form_errors[index + 1] = false;
        return (this.canDoNextStep = true);
      }
      this.form_errors[index + 1] = true;
      return (this.canDoNextStep = false);
    },
  },
};
</script>