import * as _ from 'lodash-es'
import { useChangeable } from '@state/behaviours/changeable'
import { useTheme } from '@state/system/theme'

export default {
    setup() {
        const changeable = useChangeable()
        const theme = useTheme();

        return {
            // you can return the whole store instance to use it in the template
            changeable,
            isMobile: theme.isMobile
        }
    },
    props: {
        /**
         * Nome do componente, usado para geração de labels, ids para teste e2e e validação, caso não específicados individualmente
         */
        name: {
            type: String,
            required: false
        },
        /**
         * [v-model] Tipo depende do campo a ser usado
         * @model
         */
        value: {
            type: String | Object | Array | Boolean,
            required: false
        },
        /**
         * Label, caso ausente, será usado a propriedade name para auto geração
         */
        label: {
            type: String,
            required: false
        },
        /**
         * Placeholder, caso ausente, será usado a propriedade name para auto geração
         */
        placeholder: {
            type: String,
            required: false
        },
        /**
         * Descrição, normalmente colocada abaixo do campo, como campo de ajuda. Caso ausente, será usado a propriedade name para auto geração
         */
        description: {
            type: String,
            required: false
        },
        /**
         * Referenciação de validation-providers na execução de regras. Usado no campo de validação compostas, ou dependentes de outros campos / valores
         */
        vid: {
            type: String,
            required: false
        },
        /**
         * String ou Objeto contendo as regras de validação do campo
         */
        rules: {
            type: String | Object,
            required: false
        },
        /**
         * Usado para ser referenciado dentro do cypress, para testes e2e. Caso ausente, será usado o nome
         */
        testId: {
            type: String,
            required: false
        },
        /**
         * Define se o campo é atualizado em tempo real, ou somente após o campo ter sido preenchido e válido
         */
        immediate: {
            type: Boolean,
            default: false,
            required: false
        },
        /**
         * Define se o campo é trackedo através do state do changeable. Campos sem tracking não são marcados para salvamento automático
         */
        untracked: {
            type: Boolean,
            default: false,
            required: false
        },

        /**
         * Define o nome do campo a ser trackeado. Caso não definido, é usado a prop name.
         */
        trackId: {
            type: String,
            default: "",
            required: false
        },

        /**
         * Dados adicionais a serem enviados em conjunto com o campo trackeado
         */
        trackRoot: {
            type: String,
            default: "",
            required: false
        },
        /**
         * Dados adicionais a serem enviados em conjunto com o campo trackeado
         */
        trackData: {
            type: Object,
            default: () => ({}),
            required: false
        },

        /**
         * Torna o campo reativo, permitindo ouvir os eventos de atualização
         */
        reactive: {
            type: Boolean,
            default: true,
            required: false
        },



        /**
         * Classe CSS aplicada ao label. Ignorada se slot label é aplicado
         */
        labelClass: {
            type: String,
            required: false
        },
    },
    data() {
        return {
            internalValue: null,
            fieldsetId: 'f-fieldset-' + this._uid,
            inputId: 'f-field-' + this._uid,
            internalLabel: this.label,
            internalPlaceholder: this.placeholder,
            internalDescription: this.description,
            internalTestId: this.testId,
            internalVid: this.vid,
            internalTrackId: this.trackId,
            internalRules: this.rules
        };
    },
    created() {
        this.internalValue = this.value;
    },
    mounted() {
        if (this.name) {
            _.each(
                {
                    'internalLabel': 'label',
                    'internalPlaceholder': 'placeholder',
                    'internalDescription': 'description'
                },
                (field, internal) => this[internal] = !_.isUndefined(this[internal]) ? this[internal] : this._textResolver(field)
            );

            this.internalTestId = this.internalTestId || this.name;
            this.internalVid = this.internalVid || this.name;

            this.internalTrackId = this.internalTrackId || this.name;
        }
    },
    methods: {
        _textResolver(type) {
            return this.$t(`${this.name}.${type}`)
        },
        dispatchEvent(name, value) {

            let trackId = this.internalTrackId
            let addTracks = {};

            if (!_.isEmpty(this.trackRoot)) {
                trackId = this.trackRoot + '.' + trackId

                if (!_.isEmpty(this.trackData)) {
                    for (var addTrackId in this.trackData) {
                        addTracks[this.trackRoot + '.' + addTrackId] = this.trackData[addTrackId];
                    }
                }
            }

            if (this.immediate) {
                !this.untracked && this.changeable.placeChange(trackId, value, addTracks)
                this.$emit('input', value)
            } else {
                this.$nextTick(() => {
                    this.$refs.validator.validateSilent().then(({ valid }) => {
                        if (valid) {
                            !this.untracked && this.changeable.placeChange(trackId, value, addTracks)
                            // EMITS INPUT TO UPDATE v-model
                            this.$emit('input', value)
                        }
                    });
                });
            }
        }
    },

    watch: {
        'value': {
            handler(newValue, oldValue) {
                if (this.reactive) {
                    this.internalValue = newValue
                }
            },
            deep: true
        }
    }
}
