  <template>
  <div id="page-config">
    <h4>
      {{ $t("lgpd.title") }}
    </h4>
    <h1>
      <p>
        {{ $t("lgpd.subtitle") }}
      </p>
    </h1>
    <form @submit.prevent class="mb-4">
      <validation-observer ref="lgpdRules">
        <div class="vx-row mb-9">
          <form-field-radio
            name="lgpd.declaration_of_authenticity"
            v-model="lgpd.declaration_of_authenticity"
            rules="required"
            :options="domain.domains.radio_boolean_options"
          />

          <form-field-radio
            name="lgpd.data_holder"
            v-model="lgpd.data_holder"
            rules="required"
            :options="domain.domains.data_holder"
          />
        </div>

        <div class="vx-row mb-9" v-if="!lgpd.data_holder">
          <h4>
            {{ $t("lgpd.sections.legal_agent_qualifications.title") }}
          </h4>

          <h1>{{ $t("lgpd.sections.legal_agent_qualifications.subtitle") }}</h1>

          <form-field-input
            name="lgpd.legal_agent_name"
            v-model="lgpd.legal_agent_name"
            rules="required|full-name"
          />

          <form-field-input
            name="lgpd.legal_agent_cpf"
            v-model="lgpd.legal_agent_cpf"
            mask="###.###.###-##"
            rules="required"
          />

          <form-field-input
            name="lgpd.legal_agent_address"
            v-model="lgpd.legal_agent_address"
            rules="required"
          />

          <form-field-phone
            name="lgpd.legal_agent_phone"
            v-model="lgpd.legal_agent_phone"
            phone-type="cellphone"
            mask="(##) #####-####"
            rules="required"
          />

          <form-field-input
            name="lgpd.legal_agent_email"
            v-model="lgpd.legal_agent_email"
            rules="required|email"
          />

          <h4>
            {{ $t("lgpd.sections.comprovation_power_grant.title") }}
          </h4>

          <h1>{{ $t("lgpd.sections.comprovation_power_grant.subtitle") }}</h1>

          <form-field-upload
            name="lgpd.legal_agent_document"
            v-model="lgpd.legal_agent_document"
            rules="required"
            type="document"
            :clearable="true"
          />

          <form-field-upload
            name="lgpd.legal_agent_representative_document"
            v-model="lgpd.legal_agent_representative_document"
            rules="required"
            type="document"
            :clearable="true"
          />
        </div>

        <div class="vx-row mb-9">
          <h4>
            {{ $t("lgpd.sections.personal_data_holder.title") }}
          </h4>
          <h1>{{ $t("lgpd.sections.personal_data_holder.subtitle") }}</h1>

          <div class="form-row">
            <form-field-input
              name="lgpd.data_holder_name"
              v-model="lgpd.data_holder_name"
              rules="required|full-name"
            />

            <form-field-input
              name="lgpd.data_holder_cpf"
              v-model="lgpd.data_holder_cpf"
              rules="required"
              mask="###.###.###-##"
            />

            <form-field-phone
              name="lgpd.data_holder_phone"
              v-model="lgpd.data_holder_phone"
              phone-type="cellphone"
              mask="(##) #####-####"
              rules="required"
            />

            <form-field-input
              name="lgpd.data_holder_email"
              v-model="lgpd.data_holder_email"
              rules="required|email"
            />

            <form-field-checkbox
              name="lgpd.data_holder_category"
              v-model="lgpd.data_holder_category"
              rules="required"
              :options="domain.domains.data_holder_category"
            />

            <form-field-textarea
              name="lgpd.data_holder_additional_info"
              v-model="lgpd.data_holder_additional_info"
              rules="required"
            />

            <h4>
              {{ $t("lgpd.sections.identity_comprovation.title") }}
            </h4>
            <h1>{{ $t("lgpd.sections.identity_comprovation.subtitle") }}</h1>

            <form-field-upload
              name="lgpd.data_holder_document"
              v-model="lgpd.data_holder_document"
              type="document"
              :clearable="true"
              rules="required"
            />

            <h4 class="pt-4">
              {{ $t("lgpd.sections.data_holder_rights.title") }}
            </h4>
            <h1>{{ $t("lgpd.sections.data_holder_rights.subtitle") }}</h1>

            <form-field-radio
              name="lgpd.confirm_treatment_data"
              v-model="lgpd.confirm_treatment_data"
              :options="domain.domains.radio_boolean_options"
              rules="required"
            />

            <form-field-radio
              name="lgpd.confirm_access_data"
              v-model="lgpd.confirm_access_data"
              :options="domain.domains.radio_boolean_options"
              rules="required"
            />

            <form-field-radio
              name="lgpd.hide_data"
              v-model="lgpd.hide_data"
              :options="domain.domains.radio_boolean_options"
              rules="required"
            />

            <h4>
              {{ $t("lgpd.sections.data_holder_rights_actions.title") }}
            </h4>

            <form-field-checkbox
              name="lgpd.exercise_rights"
              v-model="lgpd.exercise_rights"
              :options="domain.domains.exercise_rights"
              rules="required"
            />

            <h4>
              {{ $t("lgpd.sections.data_request.title") }}
            </h4>

            <form-field-textarea
              name="lgpd.data_request"
              v-model="lgpd.data_request"
              rules="required"
            />

            <h4>
              {{ $t("lgpd.sections.data_request.title") }}
            </h4>

            <form-field-radio
              name="lgpd.result_request"
              v-model="lgpd.result_request"
              :options="domain.domains.result_request"
              rules="required"
            />

            <b-button
              variant="primary"
              class="btn-sm w-full"
              @click="submitForm()"
              pill
            >
              {{ $t("lgpd.btn_finish") }}
            </b-button>
          </div>
        </div>
      </validation-observer>
    </form>
  </div>
</template>

  <script>
import companyMixin from "@state/company";
import { useLgpd } from "@state/user/lgpd";
import { useDomain } from "@state/domain/domain";
import * as FormComponents from "@components/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  mixins: [companyMixin],
  components: {
    ...FormComponents,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    service: {
      type: Object,
    },
  },
  setup() {
    const lgpd = useLgpd();
    const domain = useDomain();

    return {
      lgpd,
      domain,
    };
  },
  created() {
    this.$root.loading = true;

    this.lgpd.read().then(() => {
      this.$root.loading = false;
    });
  },
  methods: {
    submitForm() {
      this.$refs.lgpdRules.validate().then((success) => {
        this.$root.loading = true;

        if (!success) {
          this.inputFocus();
          this.$root.loading = false;
          return;
        }

        this.lgpd
          .create()
          .then(() => {
            this.$root.loading = false;
          })
          .catch((error) => {
            this.$root.loading = false;
            console.log(error);
          });
      });
    },
    inputFocus() {
      this.$nextTick(() => {
        const el = this.$el.querySelector(".is-invalid");
        el.scrollIntoView();
        return;
      });
    },
  },
};
</script>

