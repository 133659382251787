<template>
  <div>
    <b-navbar
      type="dark"
      variant="primary"
      v-if="selectiveProcessId && finished"
    >
      <b-navbar-brand href="#"
        >Processo Seletivo #{{ selectiveProcessId }}
      </b-navbar-brand>

      <b-button
        variant="light"
        class="my-2 my-sm-0"
        @click="returnToCaller"
        type="button"
        pill
        >Retornar</b-button
      >
    </b-navbar>
    <div class="container mt-20">
      <b-row
        v-if="test.allowed_time > 0 && started && !finished"
        vs-type="flex"
        vs-justify="flex-end"
        class="border-bottom mb-2 pb-2"
        style="margin: 0 auto; align-items: center"
      >
        <div>
          <span><strong>Tempo limite do teste:</strong></span>
          <span
            class="vr-countdown inline-flex bg-warning text-warning-dark text-xs font-bold px-2 py-1 ml-2 rounded-lg"
          >
            <i class="far fa-clock mr-1 fa-lg"></i>
            <timer
              ref="globalTimer"
              :paused="true"
              :counter="test.allowed_time"
              v-slot="{ timer }"
              @stopped="finishEvaluation('counter')"
            >
              {{ timer.remaining | timer }}
            </timer>
          </span>
        </div>
      </b-row>

      <b-row
        vs-type="flex"
        vs-justify="center"
        class="video-interview"
        style="margin: 0 auto"
      >
        <b-col vs-xs="12" vs-lg="5">
          <div
            class="rounded-xl text-center d-flex align-items-center justify-content-center vh-90"
            v-if="test"
          >
            <!-- TELA DE ERRO QUANDO NÂO É POSSÌVEL EXECUTAR VÍDEO ENTREVISTA -->
            <template v-if="failSystemCheck">
              <div class="text-center text-lg">
                <div class="h3 font-bold text-dark mb-2">
                  {{ $t("evaluation.form.browser-not-supported") }}
                </div>
                <div class="mb-4 no-copy">
                  <p>
                    {{
                      $t("evaluation.form.please-use-one-of-the-browsers-below")
                    }}
                  </p>
                  <div class="d-flex align-items-center justify-content-center">
                    <a
                      class="rounded p-3 border mr-2"
                      href="https://www.google.com/intl/pt-BR/chrome/"
                      title="Google Chrome"
                      target="_blank"
                    >
                      <img
                        width="40"
                        src="https://www.google.com/chrome/static/images/chrome-logo.svg"
                        alt=""
                      />
                      Chrome
                    </a>
                    <a
                      class="rounded p-3 border"
                      href="https://www.mozilla.org/pt-BR/firefox/new/"
                      title="Google Chrome"
                      target="_blank"
                    >
                      <img
                        width="40"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Firefox_Logo%2C_2017.svg/512px-Firefox_Logo%2C_2017.svg.png"
                        alt=""
                      />
                      Firefox
                    </a>
                  </div>
                </div>
              </div>
            </template>

            <form-wizard
              ref="wizard"
              class="form-wizard-hidden w-100"
              color="rgba(var(--vs-success), 1)"
              :title="null"
              :subtitle="null"
              :hideButtons="true"
              finishButtonText="Submit"
              v-if="!failSystemCheck"
              @on-complete="formSubmitted"
            >
              <!-- style="height: 100%; margin-top: 150px;" -->

              <span slot="step"></span>

              <tab-content>
                <div class="text-center text-lg" id="survey-intro-1">
                  <!-- <img :src="require(`@assets/images/survey/illust-video.svg`)" class="mb-5 w-40" alt=""> -->
                  <div class="h3 font-bold text-dark mb-2">
                    {{
                      $t("evaluation.form.video-interview.hello_user", [
                        $auth.user().name,
                      ])
                    }}
                  </div>
                  <div class="mb-4 no-copy" v-if="test.type == 'video'">
                    <div>
                      {{ $t("evaluation.form.video-interview.welcome") }}
                    </div>
                    {{ $t("evaluation.form.video-interview.instructions") }}
                  </div>
                  <div
                    class="mb-4 no-copy"
                    v-if="test.type == 'normal'"
                    id="survey-intro-1-description"
                  >
                    <div>
                      {{
                        test.name ? test.name : $t("evaluation.form.welcome")
                      }}
                    </div>
                    <span>{{
                      test.description
                        ? test.description
                        : $t("evaluation.form.instructions")
                    }}</span>
                  </div>

                  <div class="mb-4 no-copy" v-if="test.max_time > 0">
                    <div>Tempo total do teste: {{ test.max_time | timer }}</div>
                    <small
                      >Após iniciar, o tempo continuará correndo mesmo que você
                      saia do teste.</small
                    >
                  </div>

                  <b-button
                    variant="primary"
                    type="filled"
                    @click="goToNextTab()"
                    pill
                    size="sm"
                    >{{
                      $t("evaluation.form.video-interview.continue")
                    }}</b-button
                  >
                </div>
              </tab-content>

              <!-- Escolha um local tranquilo e bem iluminado -->
              <!-- <tab-content v-if="test.options['has-video'] && !test.preview"> -->
              <tab-content :after-change="() => checkForCriteria('has-video')">
                <div class="text-center text-lg" id="survey-intro-2">
                  <!-- <img :src="require(`@assets/images/survey/quiet-place.svg`)" class="mb-4 w-40" alt="quiet-place"> -->
                  <div class="font-bold text-dark mb-2">
                    {{
                      $t("evaluation.form.choose-a-quiet-and-well-lit-place")
                    }}
                  </div>
                  <div class="mb-4 no-coyp">
                    {{
                      $t(
                        "evaluation.form.so-you-can-focus-on-what-really-matter"
                      )
                    }}
                  </div>
                  <b-button
                    variant="primary"
                    size="sm"
                    type="filled"
                    @click="goToNextTab()"
                    pill
                    >{{ $t("evaluation.form.got-it") }}</b-button
                  >
                </div>
              </tab-content>

              <!-- Se possível, use wifi ou banda larga -->
              <!-- <tab-content v-if="test.options['has-video']"> -->
              <tab-content :after-change="() => checkForCriteria('has-video')">
                <div class="text-center text-lg" id="survey-intro-3">
                  <!-- <img :src="require(`@assets/images/survey/wifi.svg`)" class="mb-4 w-40" alt="wifi"> -->
                  <div class="font-bold text-dark mb-2">
                    {{
                      $t(
                        "evaluation.form.if-possible-use-wifi-or-broadband-connection"
                      )
                    }}
                  </div>
                  <div class="mb-4 no-copy">
                    {{
                      $t(
                        "evaluation.form.this-way-you-avoid-problems-with-slowness-during-the-interview"
                      )
                    }}
                  </div>
                  <b-button
                    variant="primary"
                    size="sm"
                    type="filled"
                    @click="goToNextTab()"
                    pill
                    >{{ $t("evaluation.form.got-it") }}</b-button
                  >
                </div>
              </tab-content>

              <!-- <tab-content v-if="test.options['has-video']"> -->
              <tab-content
                :after-change="() => checkForCriteria('checks-video')"
              >
                <div class="text-center text-lg" id="survey-intro-4">
                  <div class="font-bold text-dark mb-2">
                    <p>
                      {{
                        $t(
                          "evaluation.form.video-interview.testing-your-camera-and-audio"
                        )
                      }}
                    </p>
                    <small>{{
                      $t(
                        "evaluation.form.video-interview.testing-your-camera-and-audio-description"
                      )
                    }}</small>
                  </div>

                  <div class="mb-4">
                    <div class="mb-3">
                      <Recorder
                        :session-manager="sessionManager"
                        ref="test-recorder-video"
                        :video="true"
                        :audio="true"
                        :test-mode="true"
                        test-type="remote"
                        :max-time="5"
                        :debug="false"
                        @expired="finishVideoTest(false)"
                      />
                    </div>

                    <b-checkbox v-model="checks.video">{{
                      $t(
                        "evaluation.form.video-interview.i-can-see-me-and-the-quality-is-good"
                      )
                    }}</b-checkbox>
                  </div>
                  <b-button
                    pill
                    size="sm"
                    variant="primary"
                    type="filled"
                    @click="finishVideoTest(true)"
                    :disabled="!checks.video"
                    >{{
                      $t("evaluation.form.video-interview.continue")
                    }}</b-button
                  >
                </div>
              </tab-content>

              <!--
                            <tab-content :after-change="() => checkForCriteria('checks-audio') && !hasAlreadyAnsweredBefore">
                                <div class="text-center text-lg">
                                    <div class="font-bold text-dark mb-2">
                                        {{ $t('video-interview.testing-your-audio') }}
                                    </div>
                                    <Recorder
                                        :session-manager="sessionManager"
                                        ref="test-recorder-audio"
                                        :video="false"
                                        :audio="true"
                                        :test-mode="true"
                                        test-type="remote"
                                        :max-time="5"
                                        @expired="finishAudioTest()"
                                    />
                                    <div class="mt-3 mb-4">
                                        <vs-checkbox v-model="checks.audio">{{ $t('video-interview.i-can-hear-me-and-the-quality-is-good') }}</vs-checkbox>
                                    </div>
                                    <vs-button color="primary" type="filled" @click="goToNextTab()" :disabled="!checks.audio">{{ $t('video-interview.continue' ) }}</vs-button>
                                </div>
                            </tab-content> -->

              <!-- <tab-content v-if="test.options['has-video']"> -->
              <tab-content
                :after-change="
                  () =>
                    checkForCriteria('has-video') && !hasAlreadyAnsweredBefore
                "
              >
                <div class="text-center text-lg" id="survey-intro-5">
                  <!-- <img :src="require(`@assets/images/survey/illust-blink.svg`)" class="mb-5 w-40" alt=""> -->
                  <div class="font-bold text-dark mb-2">
                    {{ $t("evaluation.form.video-interview.all-working") }}
                  </div>
                  <div class="mb-4">
                    {{
                      $t(
                        "evaluation.form.video-interview.have-a-good-interview"
                      )
                    }}
                  </div>
                  <b-button
                    size="sm"
                    pill
                    variant="primary"
                    type="filled"
                    @click="goToNextTab()"
                    >{{ $t("evaluation.form.video-interview.start") }}</b-button
                  >
                </div>
              </tab-content>

              <!-- Introdução em texto ou vídeo -->
              <!-- <tab-content >
                            <div class="text-center text-lg">

                                    <div class="font-bold text-dark mb-2">
                                        {{ $t('if-possible-use-wifi-or-broadband-connection') }}
                                    </div>
                                    <div class="mb-4 no-copy">
                                        {{ $t('this-way-you-avoid-problems-with-slowness-during-the-interview') }}
                                    </div>
                                    <vs-button color="primary" type="filled" @click="goToNextTab()">{{ $t('start' ) }}</vs-button>
                                </div>
                            </tab-content> -->

              <tab-content :after-change="() => onStartEvaluation()">
                <!-- QUESTIONS tab-content -->
                <div
                  :key="index"
                  v-for="(question, index, counter) in questions"
                  v-show="currentQuestionIndex == counter"
                >
                  <vs-popup
                    :active.sync="popupQuestions[index]"
                    :button-close-hidden="true"
                    class="text-center vs-popup--no-header"
                  >
                    <span
                      class="inline-flex text-xs font-bold mb-4 position-absolute"
                      style="top: 6px; right: 6px"
                    >
                      <i class="far fa-clock mr-1 fa-lg"></i>
                      <span class="tracking-wide uppercase">{{
                        question.max_time | timer
                      }}</span>
                    </span>
                    <div class="my-3">
                      <div
                        class="h3 font-bold text-danger mb-4 no-copy"
                        v-if="warningRecordingAgain"
                      >
                        {{
                          $t(
                            "evaluation.form.video-interview.warning_recording_again"
                          )
                        }}
                      </div>

                      <!-- <span class="inline-flex border border-warning text-warning-dark text-xs font-bold px-2 py-1 rounded-lg mb-4">
                                                <i class="far fa-clock mr-1 fa-lg"></i>
                                                <span class="tracking-wide uppercase">{{ question.max_time | timer }} {{ $t('video-interview.time-to-response') }}</span>
                                            </span> -->

                      <div>
                        {{ $t("evaluation.form.video-interview.question") }}
                        <span class="font-bold">{{ counter + 1 }}</span>
                      </div>
                      <!-- <div>{{ $t('video-interview.question') }} <span class="font-bold">{{ counter + 1 }}</span> de <span class="font-bold">{{ Object.keys(questions).length }}</span></div> -->
                      <div
                        class="h3 font-bold text-dark mb-4 no-copy"
                        v-html="question.title"
                      ></div>

                      <hr />
                      <b-button
                        pill
                        :id="'question-popup-' + counter"
                        class="mb-2 mt-3"
                        variant="primary"
                        @click="() => (popupQuestions[index] = false)"
                        >{{
                          $t("evaluation.form.video-interview.answer-now")
                        }}</b-button
                      >
                      <div class="font-bold mt-2">
                        <timer
                          ref="timerComponent"
                          :counter="30"
                          v-slot="{ timer }"
                          @stopped="() => (popupQuestions[index] = false)"
                        >
                          <span class="text-warning">{{
                            timer.remaining | timer
                          }}</span>
                        </timer>
                        <span>
                          {{ $t("evaluation.form.video-interview.to-start") }}
                        </span>
                      </div>
                    </div>
                  </vs-popup>

                  <div
                    v-if="question.help"
                    v-b-popover.hover.top="question.help"
                    variant="primary"
                    class="d-flex align-items-center justify-content-center"
                  >
                    {{ $t("evaluation.form.video-interview.question") }}
                    <span
                      :class="
                        question.required ? 'font-bold required' : 'font-bold'
                      "
                      >{{ counter + 1 }}</span
                    >
                    <i class="far fa-question-circle fa-sm ml-1"></i>
                  </div>
                  <div v-else>
                    {{ $t("evaluation.form.video-interview.question") }}
                    <span
                      :class="
                        question.required ? 'font-bold required' : 'font-bold'
                      "
                      >{{ counter + 1 }}</span
                    >
                  </div>
                  <!-- <div>{{ $t('video-interview.question') }} <span class="font-bold">{{ counter + 1 }}</span> {{ $t('video-interview.of') }}  <span class="font-bold">{{ Object.keys(questions).length }}</span></div> -->
                  <div
                    class="font-bold text-dark mb-3 text-lg no-copy question-title"
                    v-html="question.title"
                  ></div>

                  <div class="my-4">
                    <!-- CONTAGEM REGRESSIVA -->
                    <!-- <vs-progress :percent="question_progress" color="warning">warning</vs-progress> -->
                    <!-- <vs-progress :percent="10" color="danger">warning</vs-progress> -->
                    <!-- VÍDEO -->
                    <div class="flex items-center justify-center text-white">
                      <div v-if="question.question_type == 'video'">
                        <!-- CONTAGEM REGRESSIVA -->
                        <b-progress :value="question_progress" variant="warning"
                          >warning</b-progress
                        >
                        <div class="vr-wrapper">
                          <span
                            class="vr-countdown inline-flex bg-warning text-warning-dark text-xs font-bold px-2 py-1 rounded-lg"
                          >
                            <i class="far fa-clock mr-1 fa-lg"></i>
                            <span class="tracking-wide uppercase">{{
                              timer.remaining | timer
                            }}</span>
                          </span>
                          <span
                            class="vr-rec inline-flex items-center bg-dark text-white text-xs font-bold px-2 py-1 rounded-lg"
                            v-if="question.question_type == 'video'"
                          >
                            <i class="fas fa-circle fa-xs mr-1 text-danger"></i>
                            <span class="tracking-wide uppercase">Rec</span>
                          </span>
                          <template v-if="question.question_type == 'video'">
                            <div class="vr-video">
                              <Recorder
                                :id="'question-answers-' + counter"
                                v-model="answers[index].video"
                                :session-manager="sessionManager"
                                ref="answer"
                                :record="true"
                                :max-time="question.max_time"
                                @timer="onTimer"
                                @started="onQuestionStart"
                                @expired="finishQuestion(index, counter)"
                              />
                              <!-- @stopped="onQuestionStop" -->
                            </div>
                          </template>
                        </div>
                      </div>

                      <div v-else style="width: 100%">
                        <div
                          class="d-flex align-items-center"
                          v-if="question.max_time > 0"
                        >
                          <b-progress
                            :value="question_progress"
                            variant="warning"
                            class="mr-2"
                            >warning</b-progress
                          >
                          <span
                            class="vr-countdown inline-flex bg-warning text-warning-dark text-xs font-bold px-2 py-1 rounded-lg"
                          >
                            <i class="far fa-clock mr-1 fa-lg"></i>
                            <span class="tracking-wide uppercase">{{
                              timer.remaining | timer
                            }}</span>
                          </span>
                        </div>
                        <div class="vr-wrapper mt-2" style="height: 100%">
                          <template>
                            <component
                              :id="'question-answers-' + counter"
                              :is="question.question_type"
                              v-model="answers[index]"
                              :question="question"
                              ref="answer"
                              :max-time="question.max_time"
                              @timer="onTimer"
                              @started="onQuestionStart"
                              @expired="finishQuestion(index, counter)"
                            >
                              <!-- @stopped="onQuestionStop" -->
                            </component>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 flex items-center"></div>

                  <div class="mb-4"></div>

                  <b-button
                    :id="'question-finish-' + counter"
                    variant="primary"
                    pill
                    size="sm"
                    @click="confirmFinishQuestion(index, counter)"
                    :disabled="!canFinishQuestion(index, counter)"
                  >
                    {{
                      $t("evaluation.form.video-interview.ok-already-answered")
                    }}
                  </b-button>
                </div>
              </tab-content>

              <tab-content>
                <div class="text-center text-lg">
                  <!-- <img :src="require(`@assets/images/survey/illust-check.svg`)" alt="check" class="mb-5 w-40"> -->
                  <!-- <img src="/images/illust-check.svg" class="mb-5 w-40" alt=""> -->
                  <div class="font-bold text-dark mb-2">
                    {{
                      $t("evaluation.form.video-interview.congratulations", [
                        $auth.user().name,
                      ])
                    }}
                  </div>
                  <div class="mb-2">
                    <div>
                      {{ $t("evaluation.form.video-interview.you-finished") }}
                    </div>
                  </div>
                  <div class="mb-4">
                    <div>{{ test.thank_you_note || "" }}</div>
                  </div>
                  <!--
                                    <vs-button color="primary" type="filled" @click="returnToCaller" :disabled="finished">
                                        {{ $t('common.back') }}
                                    </vs-button>                                 -->
                  <!--
                <router-link :to="{ name: step.link.name, params : step.link.params }" v-slot="{ navigate, href, isExactActive }">
                    <a :href="href" @click="navigate" :class="{ 'active': isExactActive }">
                        <span>{{ step.link.text }}</span>
                    </a>
                </router-link>                                   -->

                  <!--
                                    <vs-card class="feedback-card text-left mx-auto p-3">
                                        <div class="w-100">
                                            <div class="mb-3 text-dark font-bold">Como foi usar este site?</div>
                                            <div class="d-flex align-items-center justify-content-between px-lg-2 mb-1">
                                                <span class="cursor-pointer"><i class="far fa-star"></i></span>
                                                <span class="cursor-pointer"><i class="far fa-star"></i></span>
                                                <span class="cursor-pointer"><i class="far fa-star"></i></span>
                                                <span class="cursor-pointer"><i class="far fa-star"></i></span>
                                                <span class="cursor-pointer"><i class="far fa-star"></i></span>
                                            </div>
                                            <div class="d-flex justify-content-between text-sm px-lg-2">
                                                <span>Muito ruim</span>
                                                <span>Muito bom</span>
                                            </div>
                                        </div>
                                    </vs-card>
                                    -->
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import companyMixin from "@state/company";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import Recorder from "@/components/video/JanusRecorder.vue";
//import SessionManager from '@/components/video/SessionManager'
import JanusSessionManager from "@/components/video/JanusSessionManager";

import Discursive from "./questions/Discursive.vue";
import SingleChoice from "./questions/SingleChoice.vue";
import * as _ from "lodash-es";
import Timer from "@/components/timer/Timer.vue";

import { sprintf } from "sprintf-js";

import { VBPopover } from "bootstrap-vue";
Vue.directive("b-popover", VBPopover);

import { ModalPlugin } from "bootstrap-vue";
Vue.use(ModalPlugin);

import { useSurvey } from "@state/evaluation/survey";

function unloadFunction(e) {
  if (this.test.preview) {
    return true;
  }
  var confirmationMessage = "o/";

  e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
  return confirmationMessage; // Gecko, WebKit, Chrome <34
}

export default {
  mixins: [companyMixin],
  components: {
    FormWizard,
    TabContent,
    Recorder,
    Discursive,
    SingleChoice,
    Timer,
  },
  props: {
    interviewId: {
      required: false,
    },
  },
  setup() {
    const surveyState = useSurvey();
    //const vacancies = useVacancies();

    return {
      // you can return the whole store instance to use it in the template
      surveyState,
    };
  },
  data() {
    return {
      failSystemCheck: false,
      sessionManager: null,
      test: {
        options: {
          "checks-video": true,
          "checks-audio": true,
        },
      },
      questions: {},
      answers: {},

      popupQuestions: {},
      checks: {
        video: false,
        audio: false,
      },
      currentQuestionIndex: null,
      question_progress: 0,
      timer: {},
      started: false,
      finished: false,

      warningRecordingAgain: false,
    };
  },
  created() {
    //this.sessionManager = new SessionManager(this.$http);

    this.sessionManager = new JanusSessionManager(this.$http, "janus");
    if (this.sessionManager.checkSystemRequirements()) {
      this.fetchData();
    } else {
      this.failSystemCheck = true;
    }

    /**
     * TO FORCE A ERROR SCREEN
     */
    //this.failSystemCheck = true;
  },
  computed: {
    evaluationId() {
      if (this.interviewId) {
        return this.interviewId;
      }
      return this.$route.params.evaluationId;
    },
    selectiveProcessId() {
      return this.$route.params.selectiveProcessId;
    },
    hasAlreadyAnsweredBefore() {
      return _.some(this.questions, "answered");
    },
    hasAnsweredAllQuestions() {
      return _.every(this.questions, "answered");
    },
  },
  watch: {
    currentQuestionIndex(newValue, oldValue) {
      let questionsIndexes = Object.keys(this.questions);
      if (newValue < questionsIndexes.length) {
        let questionIndex = questionsIndexes[newValue];

        if (
          this.test.options["show-popup"] &&
          this.questions[questionIndex].question_type == "video"
        ) {
          this.enablePopup(questionIndex, newValue);
        } else {
          this.startQuestion(questionIndex, newValue);
        }
      }
    },
  },
  filters: {
    timer: function (value) {
      if (!value) {
        return "00:00";
      }

      let minutes = Math.floor(value / 60);
      let seconds = Math.round(value % 60);
      let formattted = sprintf("%02d:%02d", minutes, seconds);

      return formattted;
    },
  },

  methods: {
    finishVideoTest(forced) {
      this.$refs["test-recorder-video"].finishSession(forced);
      if (forced) {
        this.goToNextTab();
      }
    },
    finishAudioTest() {
      this.$refs["test-recorder-audio"].finishSession();
    },

    checkForCriteria(name) {
      if (this.hasAlreadyAnsweredBefore && !this.test.preview) {
        this.goToNextTab();
      } else if (this.test.options[name]) {
        return false;
      } else {
        this.goToNextTab();
      }
    },

    returnToCaller() {
      if (this.selectiveProcessId) {
        if (this.company.config.sj) {
          this.$router.push({
            name: "application-progress",
            params: {
              application_id: this.selectiveProcessId,
              sj_id: this.selectiveProcessId,
            },
          });
        } else {
          this.$router.push({
            name: "application-progress",
            params: { application_id: this.selectiveProcessId },
          });
        }
      } else {
        //this.$router.push({ name: 'evaluation-survey-finished', params: { service: this.$service } });
        this.$router.push({ name: "evaluation-survey-finished" });
      }
    },

    goToNextTab() {
      //this.sessionManager.stopPublishing();
      this.$refs.wizard.nextTab();
    },

    getNextQuestionIndex() {
      let nextIndex = 0;
      if (_.isFinite(this.currentQuestionIndex)) {
        nextIndex = this.currentQuestionIndex + 1;
      }

      const questionKeys = Object.keys(this.questions);

      console.warn(
        questionKeys,
        nextIndex,
        this.questions[questionKeys[nextIndex]]
      );

      if (!this.test.preview) {
        while (
          questionKeys[nextIndex] &&
          this.questions[questionKeys[nextIndex]].answered &&
          nextIndex < Object.keys(this.questions).length
        ) {
          nextIndex++;
        }
      }

      return nextIndex;
    },
    onStartEvaluation() {
      this.$root.loading = true;

      // IF THE USER HAS SOME QUESTIONS ANSWERED, SEND THE "CONTINUE" EVENT

      let timerEvent = "evaluation.started";

      //const hasAnswered = _.some(this.questions, 'answered');
      //if (hasAnswered) {
      //timerEvent = 'evaluation.continue';
      //}

      this.surveyState.doTimer(timerEvent);

      this._startEvaluationUI();

      // TRACK TEST START EXECUTION
      // this.startQuestion
    },
    _startEvaluationUI() {
      this.$nextTick(() => {
        this.currentQuestionIndex = this.getNextQuestionIndex();

        // BIND UNLOAD EVENTS

        this.unloadFunction = _.bind(unloadFunction, this);

        window.addEventListener("beforeunload", this.unloadFunction);
        this.$root.loading = false;

        this.started = true;
      });
    },

    startQuestion(index, counter) {
      this.disablePopup(index);

      //this.sessionManager.startRecording(index);
      if (this.questions[index].question_type == "video") {
        this.$root.loading = true;

        this.$refs.answer[counter]
          .recordSession(index)
          .then(() => {
            if (this.$refs.globalTimer) {
              this.$refs.globalTimer.resumeTimer();
            }
            this.surveyState.startQuestion(index);
          })
          .catch((error) => {
            /**
             * @todo Tratar o erro aqui do start da questão!
             */
          });
      } else {
        this.$refs.answer[counter].recordSession(index);

        //this.surveyState.startQuestion(index);

        this.$nextTick(() => {
          if (this.$refs.globalTimer) {
            this.$refs.globalTimer.resumeTimer();
          }
        });
      }

      console.warn("resumeTimer");

      //this.$refs['recorder' + index];
    },

    /**
     * Faz uma validação se a questão esta sendo avançada sem resposta
     */
    confirmFinishQuestion(index, counter) {
      // Se for do tipo 'single-choice' ou 'discursive'
      if (
        ["single-choice", "discursive"].includes(
          this.questions[index].question_type
        )
      ) {
        // Se não tiver resposta
        if (
          typeof this.answers[index][this.questions[index].question_type]
            .value === "undefined" ||
          this.answers[index][this.questions[index].question_type].value ==
            "" ||
          this.answers[index][this.questions[index].question_type].value == null
        ) {
          this.$bvModal
            .msgBoxConfirm(
              this.$t("evaluation.form.forward_without_answering") +
                " " +
                this.$t("evaluation.form.not_possible_return"),
              {
                id: "no-answer-modal",
                title: this.$t("evaluation.form.attention"),
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelVariant: "secondary",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true,
              }
            )
            .then((value) => {
              if (value) {
                // SALVAR COM FORCE!!
                this.finishQuestion(index, counter);
              }
            })
            .catch((err) => {
              // An error occurred
            });
        } else {
          this.finishQuestion(index, counter);
        }
      } else {
        this.finishQuestion(index, counter);
      }
    },

    confirmedFinishQuestion(index, counter) {
      this.finishQuestion(index, counter);
    },

    finishQuestion(index, counter) {
      this.$root.loading = true;

      if (this.$refs.globalTimer) {
        this.$refs.globalTimer.pauseTimer();
      }

      this.$refs.answer[counter].finishSession().then((answer, recordAgain) => {
        console.warn("promise-stop", answer, recordAgain);
        /**
         * Use the answer TO UPDATE THE QUESTION,. REMOVING de $emit call from inner components.
         * REf: onQuestionStop
         */
        //
        //

        // SEND THE QUESTION VALUE TO THE SERVER
        let questionsIndexes = Object.keys(this.questions);
        let questionIndex = questionsIndexes[this.currentQuestionIndex];

        if (questionIndex) {
          this.answers[questionIndex].status = "STOPPED";

          this.saveQuestion(questionIndex, this.answers[questionIndex]).then(
            () => {
              // CHECK IF THE NEXT QUESTION IF ALREADY ANSWERED ----------
              this.currentQuestionIndex = this.getNextQuestionIndex();

              //this.$refs.globalTimer.resumeTimer();

              if (
                this.currentQuestionIndex == Object.keys(this.questions).length
              ) {
                // CHECK FOR ALL QUESTIONS AND IF IS ANSWERS
                if (this.hasAnsweredAllQuestions) {
                }

                this.finishEvaluation("normal");

                //this.sessionManager.stopPublishing();
                //this.sessionManager.stopSession();

                //this.surveyState.doTimer('evaluation.finished');

                //window.removeEventListener("beforeunload", this.unloadFunction.bind(this));

                //this.$refs.wizard.nextTab();
              }
            }
          );

          //caso tenha dado algum erro, pede para regravar
          if (recordAgain) {
            this.warningRecordingAgain = true;
            //resetar compoenent da questão
            this.$refs.answer[this.currentQuestionIndex].$forceUpdate();
            //resetar timer
            this.$refs.timerComponent[this.currentQuestionIndex].restartTimer();

            /// AH  ------
            ///
            this.currentQuestionIndex = this.currentQuestionIndex - 1;
          } else {
            this.warningRecordingAgain = false;
          }
        }
        this.$root.loading = false;
      });
    },

    finishEvaluation(reason) {
      this.sessionManager.stopPublishing();
      this.sessionManager.stopSession();

      if (this.$refs.globalTimer) {
        this.$refs.globalTimer.pauseTimer();
      }

      this.surveyState
        .finish({ reason, force: false })
        .then(() => {
          window.removeEventListener(
            "beforeunload",
            this.unloadFunction,
            false
          );

          this.finished = true;

          this.$refs.wizard.nextTab();
        })
        .catch(() => {
          window.removeEventListener(
            "beforeunload",
            this.unloadFunction,
            false
          );

          this.$bvModal
            .msgBoxConfirm(
              "Nem todas as questões foram respondidas. Tem certeza que deveja continuar?",
              {
                title: "Confirmação",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true,
              }
            )
            .then((value) => {
              // SALVAR COM FORCE!!
              this.surveyState.finish({ reason, force: true }).then(() => {
                window.removeEventListener(
                  "beforeunload",
                  this.unloadFunction,
                  false
                );

                this.finished = true;

                this.$refs.wizard.nextTab();
              });
            })
            .catch((err) => {
              // An error occurred
            });
        });
      //this.surveyState.doTimer('evaluation.finished');
    },

    onQuestionStart() {
      let questionsIndexes = Object.keys(this.questions);
      let questionIndex = questionsIndexes[this.currentQuestionIndex];
      if (questionIndex) {
        this.answers[questionIndex].status = "STARTED";
      }

      this.$root.loading = false;
    },

    onTimer(timer) {
      this.timer = timer;
      this.question_progress = (1 - this.timer.remaining / timer.total) * 100;
    },

    canFinishQuestion(questionIndex, counter) {
      if (this.questions[questionIndex].min_time) {
        return (
          this.answers[questionIndex].status == "STARTED" &&
          this.questions[questionIndex].min_time < this.timer.diff
        );
      }
      /**
       * Verifica se a questão é obrigatória e valida se já foi
       * respondido para liberar o botão "Pronto, já respondi"
       */
      if (this.questions[questionIndex].required) {
        if (this.questions[questionIndex].question_type == "video") {
          // CHECKS IF THE STATUS IS RECORDING
          //'RECORDING'
          return this.answers[questionIndex].status == "STARTED";
        } else {
          if (
            !_.has(
              this.answers[questionIndex],
              this.questions[questionIndex].question_type
            ) ||
            typeof this.answers[questionIndex][
              this.questions[questionIndex].question_type
            ].value === "undefined" ||
            this.answers[questionIndex][
              this.questions[questionIndex].question_type
            ].value == "" ||
            this.answers[questionIndex][
              this.questions[questionIndex].question_type
            ].value == null
          ) {
            return false;
          }
        }
      }

      return true;
    },

    enablePopup(index, counter) {
      if (_.has(this.popupQuestions, index)) {
        this.popupQuestions[index] = true;

        var unwatch = this.$watch("popupQuestions." + index, () => {
          unwatch();
          this.startQuestion(index, counter);
        });
      }
      return true;
    },

    disablePopup(index) {
      this.popupQuestions[index] = false;
      return true;
    },

    fetchData() {
      this.$root.loading = true;

      // LOAD QUESTIONS
      this.surveyState.survey_uuid = this.evaluationId;
      this.surveyState.selective_process_id = this.selectiveProcessId;

      this.surveyState
        .read()
        .then(({ data }) => {
          this.surveyState.doTimer("evaluation.opened");

          this.test = data;
          this.questions = this.test.questions;

          if (this.test.can_continue) {
            if (this.test.expired) {
              //this.$router.push({ name: 'evaluation-survey-expired', params: { service: this.$service } });
              this.$router.push({ name: "evaluation-survey-expired" });
            } else {
              for (var i in this.questions) {
                this.$set(this.popupQuestions, i, false);

                let answer = {};
                answer[this.questions[i].question_type] = {};
                answer.status = "NOT_STARTED";

                this.$set(this.answers, i, answer);
              }
            }

            if (this.test.started) {
              this.goToNextTab();
              //this._startEvaluationUI();
            }
          } else {
            if (this.test.expired && this.test.status != "f") {
              //this.$router.push({ name: 'evaluation-survey-expired', params: { service: this.$service } });
              this.$router.push({ name: "evaluation-survey-expired" });
            } else {
              if (this.selectiveProcessId) {
                this.$router.push({
                  name: "application-progress",
                  params: { application_id: this.selectiveProcessId },
                });
              } else {
                //this.$router.push({ name: 'evaluation-survey-finished', params: { service: this.$service } });
                this.$router.push({ name: "evaluation-survey-finished" });
              }
            }
          }

          this.$root.loading = false;

          if (this.test.preview) {
            //this.$nextTick(() => this.goToNextTab());
          }
          //this.$nextTick(() => this.$refs.wizard.reset());
        })
        .catch((error) => {
          this.$root.loading = false;
        });
    },

    saveQuestion(questionIndex, answer) {
      if (this.questions[questionIndex]) {
        this.$root.loading = true;

        return this.surveyState
          .finishQuestion(questionIndex, answer)
          .then(({ data }) => {
            this.$root.loading = false;
          })
          .catch((error) => {
            this.$root.loading = false;
          });
      }
    },

    /*
        validateCard(prev, next) {
        },
        formatTime(seconds) {
            if (seconds >= 60) {
                return this.$i18n.t('video-interview.minutes', [Math.round(seconds / 60, 1)]);
            } else {
                return this.$i18n.t('video-interview.seconds', [seconds]);
            }
        },
        formSubmitted() {
            return new Promise((resolve, reject) => {
                //let result = {};
                for (var i in this.response) {
                    this.form_errors[i] = _.some(this.response[i], _.isEmpty);
                }
                if (_.every(this.form_errors)) {
                    reject();
                } else {
                    this.$vs.loading({
                        //container: '#div-with-loading',
                        scale: 0.6
                    });
                    this.$http
                        .post("/evaluation/disc/answers", this.response)
                        .then(({ data }) => {
                            resolve(true);
                        });
                }
            });
        }
        */
    formSubmitted() {},
  },
};
</script>