<template>
    <!-- O componente disc-factors-bar recebe o resultado dos fatores disc e retorna porcentagens e larguras das 4 barras -->
    <div class="disc-factors-bar--container">
        <div class="disc-factors-bar--wrap"
            v-b-toggle="UseCollapse == true ? 'collapse-' + letter : ''"
            v-for="(perc, letter) in percents" :style="{ width: perc + '%'}" >
            <div :class="['disc-factors-bar', 'disc-factors-bar--' + letter, perc <= minLabel ? 'min-label' : '']" :id="'disc-popover-' + letter"><span>{{ Math.round(perc) + "%" }}</span></div>
            <disc-factor-icon v-if="ShowFactorIcon == true" :factor="letter" />
        </div>
        <!-- POPOVERS -->
        <b-popover target="disc-popover-d" triggers="hover focus" placement="bottom" boundary="viewport">
            <template #title>
                <div class="disc-popover--header disc-color-d">
                    <span v-if="percents.d >= highFactor">
                        Alta Dominância
                    </span>
                    <span v-else>
                        Baixa Dominância
                    </span>
                </div>
            </template>
            <div v-if="percents.d >= highFactor">
                <div class="badge badge-disc-d rounded">Competitivo</div>
                <div class="badge badge-disc-d rounded">Direto</div>
                <div class="badge badge-disc-d rounded">Assertivo</div>
            </div>
            <div v-else>
                <div class="badge badge-disc-d rounded">Moderado</div>
                <div class="badge badge-disc-d rounded">Diplomático</div>
                <div class="badge badge-disc-d rounded">Cuidadoso</div>
            </div>
        </b-popover>

        <b-popover target="disc-popover-i" triggers="hover focus" placement="bottom" boundary="viewport">
            <template #title>
                <div class="disc-popover--header disc-color-i">
                    <span v-if="percents.i >= highFactor">
                        Alta Influência
                    </span>
                    <span v-else>
                        Baixa Influência
                    </span>
                </div>
            </template>
            <div v-if="percents.i >= highFactor">
                <div class="badge badge-disc-i rounded">Confiante</div>
                <div class="badge badge-disc-i rounded">Sociável</div>
                <div class="badge badge-disc-i rounded">Persuasivo</div>
            </div>
            <div v-else>
                <div class="badge badge-disc-i rounded">Reflexivo</div>
                <div class="badge badge-disc-i rounded">Reservado</div>
                <div class="badge badge-disc-i rounded">Cético</div>
            </div>
        </b-popover>

        <b-popover target="disc-popover-s" triggers="hover focus" placement="bottom" boundary="viewport">
            <template #title>
                <div class="disc-popover--header disc-color-s">
                    <span v-if="percents.s >= highFactor">
                        Alta eStabilidade
                    </span>
                    <span v-else>
                        Baixa eStabilidade
                    </span>
                </div>
            </template>
            <div v-if="percents.s >= highFactor">
                <div class="badge badge-disc-s rounded">Empático</div>
                <div class="badge badge-disc-s rounded">Paciente</div>
                <div class="badge badge-disc-s rounded">Ponderado</div>
            </div>
            <div v-else>
                <div class="badge badge-disc-s rounded">Ativo</div>
                <div class="badge badge-disc-s rounded">Inquieto</div>
                <div class="badge badge-disc-s rounded">Flexível</div>
            </div>
        </b-popover>

        <b-popover target="disc-popover-c" triggers="hover focus" placement="bottom" boundary="viewport">
            <template #title>
                <div class="disc-popover--header disc-color-c">
                    <span v-if="percents.c >= highFactor">
                        Alta Conformidade
                    </span>
                    <span v-else>
                        Baixa Conformidade
                    </span>
                </div>
            </template>
            <div v-if="percents.c >= highFactor">
                <div class="badge badge-disc-c rounded">Detalhista</div>
                <div class="badge badge-disc-c rounded">Preciso</div>
                <div class="badge badge-disc-c rounded">Precavido</div>
            </div>
            <div v-else>
                <div class="badge badge-disc-c rounded">Audacioso</div>
                <div class="badge badge-disc-c rounded">Firme</div>
                <div class="badge badge-disc-c rounded">Impulsivo</div>
            </div>
        </b-popover>
    </div>

</template>

<script>
import DiscFactorIcon from './DiscFactorIcon.vue';
import FactorCalculation from './mixins/FactorCalculation.js';

export default {
    components: {
        DiscFactorIcon,
    },

    mixins: [
        FactorCalculation
    ],

    props: {
        UseCollapse: {
            type: Boolean,
            default: false
        },
        ShowFactorIcon: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            minLabel: 10
        }
    },
}
</script>

<style lang="scss">

    .min-label {
        span {
            visibility: hidden;
        }
    }

    $disc-color-d: #F73B3B;
    $disc-color-i: #F5B800;
    $disc-color-s: #52C01B;
    $disc-color-c: #5197E6;

    .disc-color-d {
        color: darken($disc-color-d, 10%);
    }

    .disc-color-i {
        color: darken($disc-color-i, 13%);
    }

    .disc-color-s {
        color: darken($disc-color-s, 10%);
    }

    .disc-color-c {
        color: darken($disc-color-c, 10%);
    }

    .disc-factors-bar--container {
        min-width: 210px;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .disc-factors-bar--wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.not-collapsed {
            .disc-factors-bar {
                border: 2px solid rgb(0 0 0 / 25%);
                box-shadow: 1px 1px 3px rgb(0 0 0 / 12%);
            }
        }

        &.collapsed {
            .disc-factors-bar {
                cursor: pointer;
            }
        }
    }

    .disc-factors-bar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0 5px 0;
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: bold;
    }

    .disc-factors-bar--d {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        color: #fff;
        background-color: $disc-color-d;
    }

    .disc-factors-bar--i {
        color: #fff;
        background-color: $disc-color-i;
    }

    .disc-factors-bar--s {
        color: #fff;
        background-color: $disc-color-s;
    }

    .disc-factors-bar--c {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        color: #fff;
        background-color: $disc-color-c;
    }

    .disc-popover--header {
        .disc-tag {
            margin-right: 2px;
        }
    }

    .badge {
        text-transform: uppercase;
    }

    .badge-disc-d {
        border: 1px solid $disc-color-d;
        background-color: lighten($disc-color-d, 37%);
        color: darken($disc-color-d, 10%)
    }

    .badge-disc-i {
        border: 1px solid $disc-color-i;
        background-color: lighten($disc-color-i, 51%);
        color: darken($disc-color-i, 10%)
    }

    .badge-disc-s {
        border: 1px solid $disc-color-s;
        background-color: lighten($disc-color-s, 51%);
        color: darken($disc-color-s, 10%)
    }

    .badge-disc-c {
        border: 1px solid $disc-color-c;
        background-color: lighten($disc-color-c, 35%);
        color: darken($disc-color-c, 10%)
    }
</style>
