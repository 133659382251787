<template>
  <section
    id="image-with-list"
    data-scroll-to="image-with-list"
    class="d-flex flex-column gap-4"
  >
    <div class="text-center">
      <h3>
        Mais de
        <number
          class="bold transition"
          ref="number2"
          :from="numberFrom"
          :format="theFormat"
          animationPaused
          :to="numberTo"
          :duration="duration"
          easing="Power4.easeOut"
        />
        oportunidades de trabalho!
      </h3>
    </div>
    <div class="d-flex row">
      <div class="col-md py-md-0 py-4">
        <img
          v-if="imageUrl"
          class="img-fluid w-100"
          :src="imagesList[imageUrl]"
        />
      </div>
      <div class="col-md py-md-0 py-4">
        <p>Use o SelectyJobs como sua chave para o sucesso!</p>
        <p>
          Aqui você tem um parceiro para te apoiar nesse momento tão importante
          que é a busca pelo seu próximo trabalho!
        </p>
        <ul class="list-unstyled">
          <li class="d-flex align-items-center gap-2">
            <CheckIcon /> 100% gratuito para candidatos!
          </li>
          <li class="d-flex align-items-center gap-2">
            <CheckIcon /> Sem limite de candidaturas
          </li>
          <li class="d-flex align-items-center gap-2">
            <CheckIcon /> Somente etapas que fazem sentido
          </li>
          <li class="d-flex align-items-center gap-2">
            <CheckIcon /> Vagas reais em empresas reais
          </li>
          <li class="d-flex align-items-center gap-2">
            <CheckIcon /> Seu perfil profissional sendo visto e consultado por
            milhares de empresas!
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import images from "@mixins/images";
import { CheckIcon } from "@vue-hero-icons/solid";
import { useVacancies } from "@state/vacancy/vacancies";

export default {
  props: ["imageUrl"],
  mixins: [images],
  components: {
    CheckIcon,
  },
  setup() {
    const vacancies = useVacancies();

    return {
      vacancies,
    };
  },
  data() {
    return {
      numberFrom: 0,
      numberTo: 0,
      duration: 4,
    };
  },
  mounted() {
    this.vacancies
      .countVacancies()
      .then((response) => {
        this.numberTo = Math.floor(response / 10) * 10;
      })
      .then(() => {
        this.$refs.number2.play();
      });
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
  },
};
</script>
