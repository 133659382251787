<i18n>
{
    "pt_BR": {
        "achiever": "Realizador",
        "agent": "Representante",
        "appraiser": "Avaliador",
        "counselor": "Conselheiro",
        "creative": "Criador",
        "developer": "Desbravador",
        "inspirational": "Inspirador",
        "investigator": "Investigador",
        "objective thinker": "Pensador Objetivo",
        "overshift": "Expandido",
        "perfectionist": "Perfeccionista",
        "persuader": "Convincente",
        "pratitioner": "Praticante",
        "promoter": "Promotor",
        "result-oriented": "Orientado a Resultados",
        "specialist": "Especialista",
        "tight": "tight",
        "undershift": "Comprimido"
    },
    "en" : {
        "achiever": "Achiever",
        "agent": "Agent",
        "appraiser": "Appraiser",
        "counselor": "Counselor",
        "creative": "Creator",
        "developer": "Developer",
        "inspirational": "Inspirational",
        "investigator": "Investigator",
        "objective thinker": "Objective Thinker",
        "overshift": "Overshift",
        "perfectionist": "Perfectionist",
        "persuader": "Persuader",
        "pratitioner": "Pratitioner",
        "promoter": "Promoter",
        "result-oriented": "Result Oriented",
        "specialist": "Specialist",
        "tight": "Tight",
        "undershift": "Undershift"
    }
}
</i18n>

<template>
    <div>
        <!-- O card recebe a prop direction (horizontal ou vertical) e aplica a classe respectiva - disc-card--horizontal ou disc-card--vertical -->
        <!--
            O card deve se adaptar de acordo com o perfil disc recebido no teste, isso altera ícone, título do perfil e cor da borda.
            O ícone é o componente disc-profile-icon. A borda é uma classe css disc-card--[nome-do-perfil].
        -->
        <div :class="['flex-container w-100 disc-card disc-card--' + orientation, 'disc-card--' + profile]">
            <div class="flex-panel">
                <div class="flex-element disc-card--profile-content">
                    <!-- Ícone de perfil disc -->
                    <disc-profile-icon :profile="profile" />
                    <div>
                        <!-- Título de perfil disc -->
                        <h3 class="mb-0">Perfil {{ $t('disc.profile.' + profile) }}</h3>
                    </div>
                </div>
                <div class="flex-element disc-content-factor">
                    <!-- O componente disc-factors-bar recebe na prop factor os valores de cada fator obtidos no teste e se comporta de acordo. -->
                    <disc-factors-bar :factor="factor"></disc-factors-bar>
                </div>
            </div>
        </div>
        <!-- MODAL QUANDO CLICA NO CARD -->
        <b-modal id="modal-disc-details" size="xl" scrollable body-class="modal-gray-bg p-0" :title="modalTitle">
            <b-tabs class="nav-tabs-primary-wrapper nav-tabs-primary" fill content-class="p-3 bg-lightgray" nav-class="nav-tabs-primary">
                <b-tab title="Perfil" active>
                    <disc-profile-header :pattern="pattern" />
                    <div class="mb-3"></div>
                    <disc-profile-description :chart="factor" :pattern="pattern" />
                </b-tab>
                <b-tab title="Fatores">
                    <div class="d-flex align-items-center text-sm mb-1"><i class="fas fa-info-circle fa-xs mr-1"></i>Clique nas barras para ler a descrição de cada fator</div>
                    <disc-factors-bar :factor="factor" :ShowFactorIcon="false" :UseCollapse="true"></disc-factors-bar>

                    <b-collapse id="collapse-d" accordion="my-accordion" visible>
                        <div v-if="percents.d >= highFactor">
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                    <disc-factor-icon factor="d" class="mr-2" />
                                    <span class="">
                                        Alta Dominância
                                    </span>
                                </div>
                                <b-list-group flush>
                                    <b-list-group-item>Assertivo</b-list-group-item>
                                    <b-list-group-item>Competitivo</b-list-group-item>
                                    <b-list-group-item>Elevada autoestima</b-list-group-item>
                                    <b-list-group-item>Direto</b-list-group-item>
                                    <b-list-group-item>Objetivo</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="d-flex">
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1 mr-lg-3">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                        <i class="fas fa-plus-circle mr-2"></i>
                                        <span>
                                            Comportamentos Positivos
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>Aventureiro</b-list-group-item>
                                        <b-list-group-item>Toma iniciativa</b-list-group-item>
                                        <b-list-group-item>Ousado</b-list-group-item>
                                        <b-list-group-item>Persistente</b-list-group-item>
                                        <b-list-group-item>Foca nos resultados</b-list-group-item>
                                        <b-list-group-item>Determinado</b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                        <i class="fas fa-minus-circle mr-2"></i>
                                        <span>
                                            Comportamentos Limitantes
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>Arrisca demais</b-list-group-item>
                                        <b-list-group-item>Arrogante</b-list-group-item>
                                        <b-list-group-item>Impulsivo</b-list-group-item>
                                        <b-list-group-item>Insensível</b-list-group-item>
                                        <b-list-group-item>Dificuldade para delegar</b-list-group-item>
                                        <b-list-group-item>Assume muitas responsabilidades</b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                    <i class="fas fa-star mr-2"></i>
                                    <span>
                                        Traz valor para a equipe
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Coordenando</b-list-group-item>
                                    <b-list-group-item>Inovando</b-list-group-item>
                                    <b-list-group-item>Com proatividade</b-list-group-item>
                                    <b-list-group-item>Com motivação diante de desafios</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                    <i class="fas fa-globe-americas mr-2"></i>
                                    <span>
                                        O ambiente ideal proporciona
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Debates para expressar pontos de vista</b-list-group-item>
                                    <b-list-group-item>Liberdade para tomar decisões</b-list-group-item>
                                    <b-list-group-item>Desafios e oportunidades</b-list-group-item>
                                    <b-list-group-item>Pouca rotina</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-d">
                                    <i class="fas fa-heartbeat mr-2"></i>
                                    <span>
                                        Sob pressão pode ser
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Muito exigente</b-list-group-item>
                                    <b-list-group-item>Agressivo</b-list-group-item>
                                    <b-list-group-item>Egoísta</b-list-group-item>
                                </b-list-group>
                            </div>
                        </div>
                        <div v-else class="card text-blackblue border border-darkbluegray">
                            <div class="card-header disc-popover--header h5 d-flex align-items-center">
                                <disc-factor-icon factor="d" class="mr-2" />
                                <span class="disc-color-d">
                                    Baixa Dominância
                                </span>
                            </div>
                            <b-list-group flush>
                                <b-list-group-item>Conservador</b-list-group-item>
                                <b-list-group-item>Moderado</b-list-group-item>
                                <b-list-group-item>Diplomático</b-list-group-item>
                                <b-list-group-item>Despretensioso</b-list-group-item>
                                <b-list-group-item>Cuidadoso</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-collapse>

                    <b-collapse id="collapse-i" accordion="my-accordion">
                        <div v-if="percents.i >= 30">
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                    <disc-factor-icon factor="i" class="mr-2" />
                                    <span class="">
                                        Alta Influência
                                    </span>
                                </div>
                                <b-list-group flush>
                                    <b-list-group-item>Confiante</b-list-group-item>
                                    <b-list-group-item>Otimista</b-list-group-item>
                                    <b-list-group-item>Persuasivo</b-list-group-item>
                                    <b-list-group-item>Sociável</b-list-group-item>
                                    <b-list-group-item>Comunicativo</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="d-flex">
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1 mr-lg-3">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                        <i class="fas fa-plus-circle mr-2"></i>
                                        <span>
                                            Comportamentos Positivos
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>Atencioso</b-list-group-item>
                                        <b-list-group-item>Encantador</b-list-group-item>
                                        <b-list-group-item>Convincente</b-list-group-item>
                                        <b-list-group-item>Caloroso</b-list-group-item>
                                        <b-list-group-item>Inspirador</b-list-group-item>
                                        <b-list-group-item>Bem-humorado</b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                        <i class="fas fa-minus-circle mr-2"></i>
                                        <span>
                                            Comportamentos Limitantes
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>Desatendo aos detalhes</b-list-group-item>
                                        <b-list-group-item>Perde o foco com facilidade</b-list-group-item>
                                        <b-list-group-item>Desorganizado</b-list-group-item>
                                        <b-list-group-item>Dificuldade em completar tarefas</b-list-group-item>
                                        <b-list-group-item>Dificuldade para gerir o tempo</b-list-group-item>
                                        <b-list-group-item>Fala sem pensar</b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                    <i class="fas fa-star mr-2"></i>
                                    <span>
                                        Traz valor para a equipe
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Resolvendo conflitos com criatividade</b-list-group-item>
                                    <b-list-group-item>Motivando os demais</b-list-group-item>
                                    <b-list-group-item>Sendo otimista</b-list-group-item>
                                    <b-list-group-item>Colaborando</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                    <i class="fas fa-globe-americas mr-2"></i>
                                    <span>
                                        O ambiente ideal proporciona
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Contato constante com as pessoas</b-list-group-item>
                                    <b-list-group-item>Espaço para ouvir ideias</b-list-group-item>
                                    <b-list-group-item>Liberdade para se comunicar</b-list-group-item>
                                    <b-list-group-item>Gestores democráticos</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-i">
                                    <i class="fas fa-heartbeat mr-2"></i>
                                    <span>
                                        Sob pressão pode ser
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Muito otimista</b-list-group-item>
                                    <b-list-group-item>Pouco realista</b-list-group-item>
                                    <b-list-group-item>Fala demais</b-list-group-item>
                                </b-list-group>
                            </div>
                        </div>
                        <div v-else class="card text-blackblue border border-darkbluegray">
                            <div class="card-header disc-popover--header h5 d-flex align-items-center">
                                <disc-factor-icon factor="i" class="mr-2" />
                                <span class="disc-color-i">
                                    Baixa Influência
                                </span>
                            </div>
                            <b-list-group flush>
                                <b-list-group-item>Sério</b-list-group-item>
                                <b-list-group-item>Cético</b-list-group-item>
                                <b-list-group-item>Reservado</b-list-group-item>
                                <b-list-group-item>Reflexivo</b-list-group-item>
                                <b-list-group-item>Racional</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-collapse>

                   <b-collapse id="collapse-s" accordion="my-accordion" visible>
                       <div v-if="percents.s >= 30">
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                    <disc-factor-icon factor="s" class="mr-2" />
                                    <span class="">
                                        Alta eStabilidade
                                    </span>
                                </div>
                                <b-list-group flush>
                                    <b-list-group-item>Bom ouvinte</b-list-group-item>
                                    <b-list-group-item>Autocontrolado</b-list-group-item>
                                    <b-list-group-item>Paciente</b-list-group-item>
                                    <b-list-group-item>Amável</b-list-group-item>
                                    <b-list-group-item>Ponderado</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="d-flex">
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1 mr-lg-3">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                        <i class="fas fa-plus-circle mr-2"></i>
                                        <span>
                                            Comportamentos Positivos
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>Amigável</b-list-group-item>
                                        <b-list-group-item>Apaziguador</b-list-group-item>
                                        <b-list-group-item>Calmo</b-list-group-item>
                                        <b-list-group-item>Compreensivo</b-list-group-item>
                                        <b-list-group-item>Leal</b-list-group-item>
                                        <b-list-group-item>Sincero</b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                        <i class="fas fa-minus-circle mr-2"></i>
                                        <span>
                                            Comportamentos Limitantes
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>Foge de conflitos</b-list-group-item>
                                        <b-list-group-item>Falta iniciativa</b-list-group-item>
                                        <b-list-group-item>Não gosta de mudanças</b-list-group-item>
                                        <b-list-group-item>Pouco flexível</b-list-group-item>
                                        <b-list-group-item>Dificuldade para estabelecer prioridades</b-list-group-item>
                                        <b-list-group-item>Pouca ambição</b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                    <i class="fas fa-star mr-2"></i>
                                    <span>
                                        Traz valor para a equipe
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Ouvindo</b-list-group-item>
                                    <b-list-group-item>Servindo</b-list-group-item>
                                    <b-list-group-item>Transmitindo calma</b-list-group-item>
                                    <b-list-group-item>Trazendo consistência</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                    <i class="fas fa-globe-americas mr-2"></i>
                                    <span>
                                        O ambiente ideal proporciona
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Estabilidade</b-list-group-item>
                                    <b-list-group-item>Poucos conflitos</b-list-group-item>
                                    <b-list-group-item>Relações de trabalho duradouras</b-list-group-item>
                                    <b-list-group-item>Privisibilidade</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-s">
                                    <i class="fas fa-heartbeat mr-2"></i>
                                    <span>
                                        Sob pressão pode ser
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Indeciso</b-list-group-item>
                                    <b-list-group-item>Inflexível</b-list-group-item>
                                    <b-list-group-item>Fechado</b-list-group-item>
                                </b-list-group>
                            </div>
                        </div>
                        <div v-else class="card text-blackblue border border-darkbluegray">
                            <div class="card-header disc-popover--header h5 d-flex align-items-center">
                                <disc-factor-icon factor="s" class="mr-2" />
                                <span class="disc-color-s">
                                    Baixa eStabilidade
                                </span>
                            </div>
                            <b-list-group flush>
                                <b-list-group-item>Ativo</b-list-group-item>
                                <b-list-group-item>Inquieto</b-list-group-item>
                                <b-list-group-item>Impetuoso</b-list-group-item>
                                <b-list-group-item>Enérgico</b-list-group-item>
                                <b-list-group-item>Flexível</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-collapse>

                    <b-collapse id="collapse-c" accordion="my-accordion" visible>
                        <div v-if="percents.c >= highFactor">
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                    <disc-factor-icon factor="c" class="mr-2" />
                                    <span class="">
                                        Alta Conformidade
                                    </span>
                                </div>
                                <b-list-group flush>
                                    <b-list-group-item>Autodisciplinado</b-list-group-item>
                                    <b-list-group-item>Preciso</b-list-group-item>
                                    <b-list-group-item>Detalhista</b-list-group-item>
                                    <b-list-group-item>Precavido</b-list-group-item>
                                    <b-list-group-item>Perfeccionista</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="d-flex">
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1 mr-lg-3">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                        <i class="fas fa-plus-circle mr-2"></i>
                                        <span>
                                            Comportamentos Positivos
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>Analítico</b-list-group-item>
                                        <b-list-group-item>Diplomático</b-list-group-item>
                                        <b-list-group-item>Busca realizações</b-list-group-item>
                                        <b-list-group-item>Cuidadoso</b-list-group-item>
                                        <b-list-group-item>Faz boas perguntas</b-list-group-item>
                                        <b-list-group-item>Pensa objetivamente</b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="card text-blackblue border border-darkbluegray flex-grow-1">
                                    <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                        <i class="fas fa-minus-circle mr-2"></i>
                                        <span>
                                            Comportamentos Limitantes
                                        </span>
                                    </div>

                                    <b-list-group flush>
                                        <b-list-group-item>Arrogante quando contrariado</b-list-group-item>
                                        <b-list-group-item>Defensivo às críticas</b-list-group-item>
                                        <b-list-group-item>Inflexível</b-list-group-item>
                                        <b-list-group-item>Muito crítico</b-list-group-item>
                                        <b-list-group-item>Lento para agir</b-list-group-item>
                                        <b-list-group-item>Parece distante e frio</b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                    <i class="fas fa-star mr-2"></i>
                                    <span>
                                        Traz valor para a equipe
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Resolve problemas usando compreensão</b-list-group-item>
                                    <b-list-group-item>Consciente e consistente</b-list-group-item>
                                    <b-list-group-item>Define e esclarece</b-list-group-item>
                                    <b-list-group-item>Obtém informação e põe à prova</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                    <i class="fas fa-globe-americas mr-2"></i>
                                    <span>
                                        O ambiente ideal proporciona
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Abertura para pensamentos críticos</b-list-group-item>
                                    <b-list-group-item>Relações estreitas com pequenos grupos</b-list-group-item>
                                    <b-list-group-item>Privacidade</b-list-group-item>
                                    <b-list-group-item>Liberdade para questionar</b-list-group-item>
                                </b-list-group>
                            </div>
                            <div class="card text-blackblue border border-darkbluegray">
                                <div class="card-header disc-popover--header h5 d-flex align-items-center disc-color-c">
                                    <i class="fas fa-heartbeat mr-2"></i>
                                    <span>
                                        Sob pressão pode ser
                                    </span>
                                </div>

                                <b-list-group flush>
                                    <b-list-group-item>Difícil de agradar</b-list-group-item>
                                    <b-list-group-item>Pessimista</b-list-group-item>
                                    <b-list-group-item>Exageradamente meticuloso</b-list-group-item>
                                </b-list-group>
                            </div>
                        </div>
                        <div v-else class="card text-blackblue border border-darkbluegray">
                            <div class="card-header disc-popover--header h5 d-flex align-items-center">
                                <disc-factor-icon factor="c" class="mr-2" />
                                <span class="disc-color-c">
                                    Baixa Conformidade
                                </span>
                            </div>
                            <b-list-group flush>
                                <b-list-group-item>Firme</b-list-group-item>
                                <b-list-group-item>Audacioso</b-list-group-item>
                                <b-list-group-item>Impulsivo</b-list-group-item>
                                <b-list-group-item>Persistente</b-list-group-item>
                                <b-list-group-item>Independente</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-collapse>
                </b-tab>
            </b-tabs>
            <template #modal-footer="{ ok }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button variant="outline-blue" @click="ok()">
                Fechar
            </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import DiscProfileIcon from './DiscProfileIcon.vue';
import DiscFactorsBar from './DiscFactorsBar.vue';
import DiscFactorIcon from './DiscFactorIcon.vue';
import FactorCalculation from './mixins/FactorCalculation.js';
import DiscProfileHeader from './DiscProfileHeader.vue';
import DiscProfileDescription from './DiscProfileDescription.vue';

export default {
    components: {
        DiscProfileIcon,
        DiscFactorsBar,
        DiscFactorIcon,
        DiscProfileHeader,
        DiscProfileDescription
    },
    mixins: [
        FactorCalculation
    ],
    props: {
        candidateName : {
            type: String,
            required: false,
        },
        orientation : {
            type: String,
            default: 'horizontal'
        },
        pattern: {
            type: Object
        },
        chart: {
            type: Object
        },
    },
    computed: {
        profile() {
            return this.pattern.name.toLowerCase();
        },
        modalTitle() {
            if (this.candidateName) {
                return 'DISC - ' + this.candidateName;
            } else {
                return 'DISC - Resultado';
            }
        }
    }
}
</script>

<style lang="scss">

    .flex-container{
        display: flex;
    }

    .flex-column, .flex-panel{
        flex-grow: 1;
    }

    .flex-panel{
        display: flex;
        flex-wrap: wrap;
        $breakpoint: 550px;
        --multiplier: calc( #{$breakpoint} - 100%);

        .flex-element{
            min-width: 33%;
            max-width: 100%;
            flex-grow: 1;
            flex-basis: calc( var(--multiplier) * 999 );
            justify-content: center;
            padding: 16px;

            &:first-child {
                flex-grow: unset;
                flex-shrink: 1;
            }
        }
    }

    .disc-card {
        display: flex;
        align-items: center;
        // max-width: 750px;
        border-radius: 8px;
        padding: 8px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        background-color: #fff;
        border-width: 2px;
        border-style: solid;
        border-color: #000;
        cursor: pointer;

        .disc-card--profile-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    .disc-card--horizontal {
        flex-direction: row;

        .disc-card--profile-content {
            flex-direction: row;
            // border-right: 1px solid #D7D9DB;
            // margin-right: 32px;
            // padding-right: 16px;
        }

        .disc-profile-icon {
            margin-right: 8px;
        }
    }
    .disc-card--vertical {
        flex-direction: column;
        justify-content: center;
        padding: 16px 16px;
        cursor: unset;

        .disc-card--profile-content {
            flex-direction: column;
            // border-bottom: 1px solid #D7D9DB;
            // margin-bottom: 32px;
            // padding-bottom: 16px;
        }

        .disc-profile-icon {
            margin-bottom: 8px;
        }

        .disc-content-factor {
            border-top: 1px solid #D7D9DB;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .disc-popover--header .fas, .far {
        opacity: .5;
    }


    // PROFILE COLORS //

    .disc-card--developer {
        border-color: #F73B3B;
    }
    .disc-card--result-oriented {
        border-color: #F45434;
    }
    .disc-card--inspirational {
        border-color: #F96C15;
    }
    .disc-card--creative {
        border-color: #F53D7A;
    }
    .disc-card--promoter {
        border-color: #DB9200;
    }
    .disc-card--persuader {
        border-color: #FA9200;
    }
    .disc-card--counselor {
        border-color: #AA980E;
    }
    .disc-card--appraiser {
        border-color: #6DA046;
    }
    .disc-card--specialist {
        border-color: #51BA1C;
    }
    .disc-card--achiever {
        border-color: #9CA51D;
    }
    .disc-card--agent {
        border-color: #81A81F;
    }
    .disc-card--investigator {
        border-color: #1AB74E;
    }
    .disc-card--objective {
        border-color: #4794EB;
    }
    .disc-card--perfectionist {
        border-color: #16A2DA;
    }
    .disc-card--practitioner {
        border-color: #13ACAE;
    }

    .nav-tabs-primary-wrapper > div:first-child {
        position: sticky;
        top: 0;
        z-index: 3;
    }

    .nav-tabs-primary {
        border-bottom: 1px solid #d2d2d2;
        background: hsl(0deg 0% 100% / 80%);
        backdrop-filter: blur(10px);

    .nav-item {
        // flex: 1;
        text-align: center;

        .nav-link {
            color: #648090;
            font-size: 1.2rem;
            font-weight: bold;
            background: transparent !important;
            background-color: transparent;
            border: 0;
            border-bottom: 4px solid transparent;
            border-radius: 0;
            transition: all 0.2s ease;

                &:hover, &:focus {
                    border-bottom-color: #d2d2d2;
                }

                &.active {
                    color: #00A5F7;
                    border-width: 0 0 4px 0;
                    border-bottom-style: solid;
                    border-bottom-color: lighten(#F9B147, 10);
                    background: transparent;
                }
            }
        }
    }
</style>
