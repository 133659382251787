<template>
  <section id="banner" data-scroll-to="banner">
    <div class="carousel-example" v-if="show && type == 'normal'">
      <swiper-container
        init="false"
        class="swiper-container"
        ref="swiper_container"
      >
        <swiper-slide v-for="(item, index) in images" :key="index">
          <a :href="item.redirect_url ? item.redirect_url : null" target="_blank">
            <img
              class="responsive"
              :src="getUrlSrc(item.src)"
              @error="renderPlaceholder($event)"
            />
          </a>
        </swiper-slide>
      </swiper-container>
    </div>
    <div
      v-else-if="type == 'redirector'"
      class="d-flex flex-column align-items-center"
    >
      <a
        class="mb-4"
        style="position: relative"
        target="_blank"
        :href="value.redirect_link"
        v-for="(value, index) in images"
        :key="index"
        @mouseover="handleHover(true)"
        @mouseleave="handleHover(false)"
      >
        <img class="img-fluid rounded redirector-img" :src="value.src" />

        <b-button
          variant="primary"
          pill
          style="position: absolute; top: 42%; left: 42%"
          v-if="isHovered"
        >
          {{ $t("common.visit") }}
        </b-button>
      </a>
    </div>
  </section>
</template>

<script>
// REMOVER ITEM DAQUI E PASSAR PARA O LOADER DE CSS / SCSS
import * as _ from "lodash-es";
import images from "@mixins/images";
import companyMixin from "@state/company";
import { useTheme } from "@state/system/theme";
import { register } from "swiper/element/bundle";
import { Navigation, Pagination } from "swiper/modules";
import bannerPlaceholder from "./assets/megabanner-example.png";

register();

export default {
  mixins: [companyMixin, images],
  props: {
    images: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: "normal",
      required: false,
    },
  },
  components: {},
  computed: {
    isSJ() {
      return this.company.config.sj;
    },
  },
  setup() {
    const theme = useTheme();

    return {
      isMobile: theme.isMobile,
    };
  },
  created() {},
  mounted() {
    const swiperOption = {
      modules: [Navigation, Pagination],
      injectStylesUrls: [],
      navigation: !this.isMobile(),
      pagination: this.isMobile(),
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    };

    // swiper element
    //const swiperEl = document.querySelector('swiper-container');
    const swiperEl = this.$refs.swiper_container;

    if (this.$refs.swiper_container) {
        // now we need to assign all parameters to Swiper element
        Object.assign(swiperEl, swiperOption);
        // and now initialize it
        swiperEl.initialize();
    }
  },
  data() {
    return {
      placeholderImage: bannerPlaceholder,
      show: true,
      isHovered: false,
    };
  },
  methods: {
    renderPlaceholder(ev) {
      ev.target.src = this.placeholderImage;
    },
    getUrlSrc(src) {
      if (this.isSJ) {
        return this.imagesList[src];
      } else {
        return src;
      }
    },
    handleHover(hovered) {
      this.isHovered = hovered;
    },
  },
};
</script>
