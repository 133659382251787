<template>
  <div v-if="vacancy" class="page-form" id="page-my-application">
    <h1>
      <!-- <b-icon v-on:click="showCancelModal()" icon="times"/> -->
      {{ $t("application.application") }}
    </h1>

    <div class="application-content">
      <div v-bind:class="{ 'last-step': isLastStep }">
        <form-wizard
          ref="formWizard"
          stepSize="xs"
          :color="company.config.theme['primary_color']"
          :title="null"
          :subtitle="null"
          finishButtonText="Finalizar"
          backButtonText="Voltar"
          nextButtonText="Avançar"
          @on-change="tabChanged"
        >
          <!-- MUST SHOW ONLY THE REQUIRED FIELDS? -->
          <tab-content
            :title="$t('application.resume')"
            class="mb-5"
            :before-change="validateCurriculum"
          >
            <Curriculum ref="curriculumComponent" />
          </tab-content>

          <tab-content
            :title="$t('application.otherData')"
            class="mb-5 second-step"
          >
            <validation-observer
              ref="enrollRules"
              tag="div"
              class="vs-collapse default vaga-div"
            >
              <div class="other-data">
                <h2>{{ $t("application.fillResume") }}</h2>

                <p>{{ $t("application.isRelated") }}?</p>

                <form-field-switch v-model="isRelated" />
                <p>{{ $t("application.isIndicated") }}?</p>

                <form-field-switch v-model="isIndicated" />

                <div v-if="isIndicated" class="centerx labelx">
                  <b-form-input
                    :label="$t('application.indicationName')"
                    :placeholder="$t('application.name')"
                    v-model="indicationName"
                  />
                </div>
              </div>
            </validation-observer>

            <div class="vaga-div">
              <vacancy-card :item="vacancy">
                <template #footer>
                  <div class="actions">
                    <b-button variant="light" v-b-modal.vacancy-modal>
                      {{ $t("application.jobDetails") }}
                    </b-button>
                  </div>
                </template>
              </vacancy-card>
            </div>
          </tab-content>

          <tab-content
            :title="$t('application.short')"
            class="mb-5 second-step"
          >
            <div class="other-data">
              <h2>{{ $t("application.editInfo") }}</h2>

              <div class="card card-edited-info">
                <p>{{ $t("application.resume") }}</p>
                <span>Você editou há 5 minutos</span>
                <b-icon
                  v-on:click="goToStep(0)"
                  class="button button-prev"
                  icon="pencil-square"
                />
              </div>

              <div class="card card-edited-info">
                <p>{{ $t("application.otherData") }}</p>
                <span>Você editou há 3 minutos</span>
                <b-icon
                  v-on:click="goToStep(1)"
                  class="button button-prev"
                  icon="pencil-square"
                />
              </div>

              <button @click="confirm()" class="confirm-button">
                {{ $t("application.confirmApplication") }}
              </button>
            </div>

            <div class="vaga-div">
              <vacancy-card :item="vacancy">
                <template #footer>
                  <div class="actions">
                    <b-button variant="light" v-b-modal.vacancy-modal>
                      {{ $t("application.jobDetails") }}
                    </b-button>
                  </div>
                </template>
              </vacancy-card>
            </div>
            <!--
            <div class="vaga-div">
              <div class="card job">
                <h2>{{vaga.title}}</h2>

                <div class="job-details">
                  <div>
                      <font-awesome-icon class="button button-prev" icon="map-marker-alt" />
                    <span>{{vaga.location}}</span>
                  </div>
                </div>

                <div class="divider"></div>

                <div class="tags">
                  <div v-for="tag in vaga.tags" class="tag">{{tag}}</div>
                </div>

                <div class="actions">
                  <button class="see-progress" v-on:click="goToVaga(vaga.id)">{{ $t('application.jobDetails') }}</button>
                </div>
              </div>
            </div>
            -->
          </tab-content>

          <template slot="footer" slot-scope="props">
            <!--
            <div class="wizard-footer-left">
              <wizard-button  v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button>
            </div>
            <div class="wizard-footer-right">
            -->
            <div class="button-div button-save-div profile-form">
              <b-button
                v-if="!props.isLastStep"
                @click="props.nextTab()"
                class="btn-save"
                color="primary"
                type="filled"
              >
                {{ $t("common.advance") }}
              </b-button>
            </div>

            <!--
              <wizard-button v-else @click.native="alert('Done')" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">  {{props.isLastStep ? 'Done' : 'Next'}}</wizard-button>
              -->
            <!-- </div> -->
          </template>
        </form-wizard>
      </div>
    </div>

    <b-modal
      id="vacancy-modal"
      size="xl"
      :cancel-disabled="true"
      :ok-disabled="true"
      :title="vacancy.occupation"
      scrollable
    >
      <VacancyFull :vacancy="vacancy" />

      <template #modal-footer>&nbsp;</template>
    </b-modal>

    <b-modal
      ref="cancel-modal"
      id="cancel-modal"
      :title="$t('application.confirmCancelTitle')"
      :ok-title="$t('application.yesCancel')"
      :cancel-title="$t('application.noCancel')"
      cancel-variant="outline-secondary"
      @ok="goBack"
      scrollable
    >
      <b-card-text>
        {{ $t("application.confirmCancelText") }}<br />
        {{ $t("application.confirmCancelComplement") }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import companyMixin from "@state/company";

import * as FormComponents from "@components/forms";

import { ValidationObserver, ValidationProvider } from "vee-validate";

import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import Curriculum from "./../profile/Curriculum.vue";
import VacancyFull from "@blocks/vacancy/VacancyFull.vue";
import VacancyCard from "@blocks/vacancy/VacancyCard.vue";
//import router from '../../router';

//import profileService from '@services/profile/Profile'

import { useVacancies } from "@state/vacancy/vacancies";
import { useApplications } from "@state/user/applications";

export default {
  mixins: [companyMixin],
  components: {
    ...FormComponents,
    FormWizard,
    TabContent,
    WizardButton,
    ValidationObserver,
    ValidationProvider,
    Curriculum,
    VacancyFull,
    VacancyCard,
  },
  props: {
    vacancyId: Number | String,
  },
  data() {
    return {
      //profileService : profileService.build(),
      //vacancyService : vacancyService.build(),
      vacancy: null,
      isRelated: false,
      isIndicated: false,
      indicationName: "",
      isLastStep: false,
    };
  },
  setup() {
    const vacancies = useVacancies();
    const applications = useApplications();

    return {
      vacancies,
      applications,
    };
  },
  created() {
    this.vacancies.read(this.vacancyId).then((res) => {
      this.vacancy = res.data;
    });
  },
  methods: {
    validateCurriculum() {
      return new Promise((resolve, reject) => {
        this.$refs.curriculumComponent
          .validate(true)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(
              "This is a custom validation error message. Click next again to get rid of the validation"
            );
          });
      });
    },
    confirm() {
      this.$refs.enrollRules.validate().then((success) => {
        if (success) {
          this.applications
            .enroll(this.vacancyId, {
              isRelated: this.isRelated,
              isIndicated: this.isIndicated,
              indicationName: this.indicationName,
            })
            .then((response) => {
              this.$router.push({
                name: "application-progress",
                params: { application_id: response.data.application_id },
              });
            });
        }
      });
    },

    goBack() {
      this.$router.go(-1);
    },
    tabChanged(prevIndex, nextIndex) {
      this.isLastStep = nextIndex == 2;
    },
    goToStep(step) {
      this.$refs.formWizard.changeTab(2, step);
    },
    goToVaga(id) {
      //router.push({ name: 'landing-page-vaga', query: { id: id } });
      this.$router.push({ name: "vacancy-show", params: { vacancy_id: id } });
    },
    showCancelModal() {
      this.$refs["cancel-modal"].show();
    },
    hideCancelModal() {
      this.$refs["cancel-modal"].hide();
    },
  },
};
</script>
