<template>
  <div id="page-config">
    <div v-if="!isSocialMediaAccount">
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <h1>{{ $t("config.title") }}</h1>
        </div>
      </div>

      <div class="divider"></div>
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <div class="edit-menu-item">
            <div class="edit-menu-item-left">
              <div class="email-div">{{ $t("common.email") }}</div>
            </div>
            <div class="edit-menu-item-right">
              <b-button v-b-modal.modal-email variant="primary" pill size="sm">
                {{ $t("common.change") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <div class="edit-menu-item">
            <div class="edit-menu-item-left">
              <div class="email-div">{{ $t("common.password") }}</div>
            </div>
            <div class="edit-menu-item-right">
              <b-button v-b-modal.modal-senha variant="primary" pill size="sm">
                {{ $t("common.change") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!-- @todo Travado vínculo com redes sociais pois estamos aguardando o SSO por questões de segurança -->
      <!-- <div class="vx-row mb-3" v-if="!isSJ">
      <div class="vx-col w-full">
        <h1>{{ $t("config.linkAccount") }}</h1>
      </div>
    </div> -->

      <!-- <div class="divider" v-if="!isSJ"></div> -->

      <!-- <div class="vx-row mb-3" v-if="!isSJ">

      <div class="vx-col w-full">
        <div class="edit-menu-item">
          <div class="justify-content-center w-100">
            <b-alert v-if="hasSocialMediaLink" variant="success" show
              >Conta já vinculada com o <strong>{{ linkedSocialMedia }}</strong>.</b-alert
            >

            <div v-else>
              <b-button
                variant="dt-secondary"
                pill
                block
                :href="ssoUrl + '/sj/redirect'"
              >
                <span>
                  <img
                    width="20px"
                    :src="
                      images['/resources/assets/images/social/sj_small.png']
                    "
                    alt="SelectyJobs"
                  />
                </span>
                <span> &nbsp; {{ $t("common.link_with_sj") }}</span>
              </b-button>
              <b-button
                variant="dt-secondary"
                pill
                block
                :href="ssoUrl + '/linkedin/redirect'"
              >
                <span>
                  <img
                    width="20px"
                    :src="
                      images['/resources/assets/images/social/linkedin.png']
                    "
                    alt="LinkedIn"
                  />
                </span>
                <span> &nbsp; {{ $t("common.link_with_linkedin") }}</span>
              </b-button>

              <b-button
                variant="dt-secondary"
                pill
                block
                :href="ssoUrl + '/facebook/redirect'"
              >
                <span>
                  <img
                    width="20px"
                    :src="
                      images['/resources/assets/images/social/facebook.png']
                    "
                    alt="Facebook"
                  />
                </span>
                <span> &nbsp; {{ $t("common.link_with_facebook") }}</span>
              </b-button>

              <b-button
                variant="dt-secondary"
                pill
                block
                :href="ssoUrl + '/google/redirect'"
              >
                <span>
                  <img
                    width="20px"
                    :src="images['/resources/assets/images/social/google.png']"
                    alt="Google"
                  />
                </span>
                <span> &nbsp; {{ $t("common.link_with_google") }}</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    </div>

    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <h1>{{ $t("config.notificationsTitle") }}</h1>
      </div>
    </div>

    <div class="divider"></div>

    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="edit-menu-item">
          <div class="edit-menu-item-left">
            <div class="email-div">
              {{ $t("config.notifications.push.title") }}
            </div>
            <div>
              {{ $t("config.notifications.push.description") }}
            </div>
          </div>
          <div class="edit-menu-item-right">
            <b-form-checkbox
              name="check-button"
              switch
              v-model="form.push_notifications"
            ></b-form-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="edit-menu-item">
          <div class="edit-menu-item-left">
            <div class="email-div">
              {{ $t("config.notifications.email.title") }}
            </div>
            <div>
              {{ $t("config.notifications.email.description") }}
            </div>
          </div>
          <div class="edit-menu-item-right">
            <b-form-checkbox
              name="check-button"
              v-model="form.email_notifications"
              switch
            ></b-form-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="edit-menu-item">
          <div class="edit-menu-item-left">
            <div class="email-div">
              {{ $t("config.notifications.whatsapp.title") }}
            </div>
            <div>
              {{ $t("config.notifications.whatsapp.description") }}
            </div>
          </div>
          <div class="edit-menu-item-right">
            <b-form-checkbox
              name="check-button"
              switch
              v-model="form.whatsapp_notifications"
            ></b-form-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <h1>{{ $t("config.dataPrivacy.title") }}</h1>
      </div>
    </div>

    <div class="divider"></div>

    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="edit-menu-item big">
          <div class="edit-menu-item-left">
            <div>
              {{ $t("config.dataPrivacy.description1") }}
              <router-link
                class="btn-link"
                :to="{ name: 'profile-configurations-lgpd' }"
              >
                {{ $t("config.dataPrivacy.description2") }}
              </router-link>
              {{ $t("config.dataPrivacy.description3") }}
            </div>
          </div>
          <div class="edit-menu-item-right">
            <a href="#" @click="showPrivacyModal">{{
              $t("config.dataPrivacy.seePrivacyPolicy")
            }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <h1>{{ $t("config.cancelAccount.title") }}</h1>
      </div>
    </div>

    <div class="divider"></div>

    <div class="vx-row mb-3">
      <div class="vx-col w-full">
        <div class="edit-menu-item big">
          <div class="edit-menu-item-left">
            <div>
              {{ $t("config.cancelAccount.description1")
              }}<b>{{ $t("config.cancelAccount.description2") }}</b>
              {{ $t("config.cancelAccount.description3") }}.
            </div>
          </div>
          <div class="edit-menu-item-right">
            <b-button variant="danger" v-b-modal.modal-cancel-account pill>
              {{ $t("config.cancelAccount.btnCancelAccount") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-senha"
      size="lg"
      centered
      :title="$t('config.password.changePassword')"
      @ok="changePassword"
      @hidden="clearPasswordForm"
      @cancel="clearPasswordForm"
      scrollable
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button
          class="border"
          variant="outline-primary"
          pill
          size="sm"
          @click="cancel()"
        >
          {{ $t("common.cancel") }}
        </b-button>
        <b-button variant="primary" pill size="sm" @click="ok()">
          {{ $t("config.password.savePassword") }}
        </b-button>
      </template>
      <form @submit.prevent autocomplete="off">
        <validation-observer ref="passwordRules">
          <div @click="showInstructions = true">
            <form-field-password
              name="curriculum.sensitive.password"
              v-model="password_form.password"
              rules="required"
              untracked
              autocomplete="off"
            />

            <form-field-password
              name="curriculum.sensitive.new_password"
              v-model="password_form.new_password"
              rules="required"
              untracked
              autocomplete="off"
            />

            <form-field-password
              name="curriculum.sensitive.new_password_confirmation"
              v-model="password_form.new_password_confirmation"
              rules="required|confirmed:curriculum.sensitive.new_password"
              untracked
              autocomplete="off"
            />
          </div>

          <password-strength-checker
            v-show="showInstructions"
            :password="password_form.new_password"
            :password_confirmation="password_form.new_password_confirmation"
          />
        </validation-observer>
      </form>
    </b-modal>

    <b-modal
      id="modal-email"
      size="lg"
      centered
      :title="$t('config.email.changeEmail')"
      @ok="changeEmail"
      @hidden="clearEmailForm"
      @cancel="clearEmailForm"
      scrollable
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button
          class="border"
          variant="outline-primary"
          pill
          size="sm"
          @click="cancel()"
        >
          {{ $t("common.cancel") }}
        </b-button>
        <b-button variant="primary" pill size="sm" @click="ok()">
          {{ $t("config.email.saveEmail") }}
        </b-button>
      </template>
      <form @submit.prevent autocomplete="off">
        <validation-observer ref="emailRules">
          <form-field-password
            name="curriculum.sensitive.password"
            v-model="email_form.password"
            rules="required"
            untracked
            autocomplete="off"
          />

          <form-field-input
            name="curriculum.sensitive.new_primary_email"
            v-model="email_form.email"
            rules="required|email"
            autocomplete="off"
            inputmode="email"
            type="email"
          />

          <form-field-input
            name="curriculum.sensitive.new_primary_email_confirmation"
            v-model="email_form.email_confirmation"
            rules="required|confirmed:curriculum.sensitive.new_primary_email"
            autocomplete="off"
            inputmode="email"
            type="email"
          />
        </validation-observer>
      </form>
    </b-modal>

    <b-modal
      id="modal-lgpd"
      cancel-variant="outline-secondary"
      ok-title="Enviar"
      cancel-title="Cancelar"
      centered
      title="Enviar Mensagem"
      @ok="sendLgpdMessage"
      @hidden="clearLgpdForm"
    >
      <validation-observer ref="lgpdRules">
        <b-form>
          <b-form-group>
            <label for="email">Assunto:</label>
            <validation-provider
              #default="{ errors }"
              name="Assunto"
              rules="required"
            >
              <v-select
                v-model="lgpd_form.subject"
                label="title"
                :class="{ 'is-invalid': errors.length > 0 }"
                :options="domains.lgpd_subject_options"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="password">Mensagem</label>
            <validation-provider
              #default="{ errors }"
              name="Assunto"
              rules="required"
            >
              <b-form-textarea
                id="textarea"
                v-model="lgpd_form.message"
                placeholder="Deixa a sua mensagem"
                rows="3"
                max-rows="6"
                :state="errors.length > 0 ? false : null"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal id="modal-privacy-policy" size="lg">
      <div v-html="policy_privacy"></div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" @click="hide" pill>{{
          $t("common.close")
        }}</b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-cancel-account"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      :ok-title="$t('config.cancelAccount.btnCancelAccount')"
      :cancel-title="$t('common.close')"
      centered
      :title="$t('config.cancelAccount.title')"
      @ok="cancelAccount"
    >
      <div>
        {{ $t("config.cancelAccount.description1")
        }}<b>{{ $t("config.cancelAccount.description2") }}</b>
        {{ $t("config.cancelAccount.description3") }}.
      </div>
      <div>
        {{ $t("config.cancelAccount.description4") }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import { VBModal } from "bootstrap-vue";
import companyMixin from "@state/company";
import * as FormComponents from "@components/forms";
import { useConfiguration } from "@state/user/configuration";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PasswordStrengthChecker from "@components/user/PasswordStrengthChecker.vue";

export default {
  mixins: [companyMixin],
  components: {
    ...FormComponents,
    ValidationProvider,
    ValidationObserver,
    PasswordStrengthChecker,
  },
  props: {
    service: {
      type: Object,
    },
  },
  computed: {
    isSocialMediaAccount() {
      return (
        _.indexOf(
          ["linkedin", "google", "facebook"],
          this.$auth.user().auth_type
        ) >= 0
      );
    },
    isSJ() {
      return this.company.config.sj;
    },
    ssoUrl() {
      return this.company.config.sso_url;
    },
  },
  setup() {
    const configuration = useConfiguration();
    const images = import.meta.glob("/resources/assets/images/social/*.png", {
      import: "default",
      eager: true,
    });

    return {
      configuration,
      images,
    };
  },
  created() {
    const user = this.$auth.user();

    if (user.auth_type !== "email") {
      this.hasSocialMediaLink = true;
      this.linkedSocialMedia = this.authType[user.auth_type];
    }

    this.form.push_notifications = user.push_notifications;
    this.form.email_notifications = user.email_notifications;
    this.form.whatsapp_notifications = user.whatsapp_notifications;

    this.$watch(
      "form",
      function (newValue) {
        if (
          newValue.push_notifications !== null ||
          newValue.email_notifications !== null ||
          newValue.whatsapp_notifications !== null
        ) {
          this.configuration.updateNotifications(newValue);
        }
      },
      { deep: true }
    );
  },
  data() {
    return {
      hasSocialMediaLink: false,
      linkedSocialMedia: "",
      showInstructions: false,
      authType: {
        google: "Google",
        linkedin: "LinkedIn",
        facebook: "Facebook",
        sj: "SelectyJobs",
      },
      //config: config,
      form: {
        push_notifications: true,
        email_notifications: true,
        whatsapp_notifications: true,
      },
      email_form: {
        password: "",
        email: "",
        email_confirmation: "",
      },
      password_form: {
        password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      lgpd_form: {
        subject: "",
        message: "",
      },
      domains: {
        lgpd_subject_options: ["Square", "Rectangle"],
      },
      policy_privacy: "",
    };
  },
  methods: {
    showPrivacyModal(e) {
      this.configuration.getPrivacyPolicy().then((response) => {
        this.policy_privacy = response.data;

        this.$bvModal.show("modal-privacy-policy");
      });
      e.preventDefault();
    },
    changeEmail(e) {
      this.$refs.emailRules.validate().then((success) => {
        if (success) {
          this.configuration.changeEmail(this.email_form).then(() => {
            this.$bvModal.hide("modal-email");
            this.$auth.fetch();
          });
        }
      });
      e.preventDefault();
    },
    changePassword(e) {
      this.$refs.passwordRules.validate().then((success) => {
        if (success) {
          this.configuration.changePassword(this.password_form).then(() => {
            this.$bvModal.hide("modal-senha");
          });
        }
      });
      e.preventDefault();
    },
    sendLgpdMessage(e) {
      this.$refs.lgpdRules.validate().then((success) => {
        if (success) {
          this.configuration.sendLgpdMessage(this.lgpd_form).then(() => {
            this.$bvModal.hide("modal-lgpd");
          });
        }
      });
      e.preventDefault();
    },
    cancelAccount() {
      this.$root.loading = true;

      this.configuration.cancelAccount().then((success) => {
        if (success) {
          this.$router.go({ name: "home" });
        }

        this.$root.loading = false;
      });
    },
    clearPasswordForm() {
      this.password_form = {
        password: "",
        new_password: "",
        new_password_confirmation: "",
      };
    },
    clearEmailForm() {
      this.email_form = {
        password: "",
        email: "",
      };
    },
    clearLgpdForm() {
      this.lgpd_form = {
        subject: "",
        message: "",
      };
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
