<template>
    <div
        class="status-container"
        :class="{'fixed' : fixed}"
    >
        <span class="status">
            <Transition
                appear
                name="fade"
                mode="out-in"
            >
                <small v-if="state">
                    <b-spinner
                        variant="primary"
                        small
                    ></b-spinner>
                    <span> Salvando alterações....</span>
                </small>
                <small v-else>
                    <b-icon
                        variant="success"
                        icon="check-circle-fill"
                        font-scale="1.3"
                        v-if="counter == 0"
                    ></b-icon>
                    <b-icon
                        variant="warning"
                        icon="exclamation-triangle-fill"
                        font-scale="1.3"
                        v-if="counter != 0"
                    ></b-icon>
                    <span> {{ $tc('changeable.indicator-text', counter) }}</span>
                </small>
            </Transition>
        </span>
    </div>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    name: "ChangeableStatus",
    props: {
        /**
         * Valor contendo o total de alterações encontradas
         */
        counter: {
            type: Number,
            default: 0,
        },
        /**
         * Define o estado do componente, se está em salvamento ou não
         */

        state: {
            type: Boolean,
            default: false,
        },
        /**
         * Define se o componente ficará fixo na tela ou se acompanha o scroll
         */
        fixed: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
<style lang="scss">
.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.25s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}
// .fade-leave-active {
//   transition: none;
// }

.fade-enter-from .fade-leave-to {
    opacity: 0;
}
</style>
<!--
<docs>
Componente usado para mostrar visualmente o estado de salvamento de um determinado formulário

Use os botões ao lado para alternar os estados

```vue
<template>
    <div class="container">
        <ChangeableStatus :state="state" :counter="counter" fixed="fixed" />

        <div>
            <b-button @click="state = !state; counter = 0" size="sm">Altenar estado</b-button>
            <b-button @click="counter++" size="sm">Incrementar contador</b-button>
            <b-button @click="fixed = !fixed;" size="sm">Alternar fixo</b-button>
        </div>
    </div>
</template>

<script>
    export default {
      data() {
        return {
            state : false,
            counter: 0,
            fixed : false
        };
      }
    }
</script>
<style lang="scss">
    .container {
        display: flex;
        justify-content: space-between;
    }
    button {

    }

</style>
```
</docs>
-->
